<template>
  <div class="container">
    <section class="derivative-section"> 
      <h2>Creators</h2>
      <div class="creators">
        <div class="creator" @click="selectCreator(creator.id)" v-for="(creator, index) in sortedCreators" :key="`creator-${index}`">
          <img class="creator__image" :class="selectedCreatorId == creator.id ? 'active':''" v-if="creator.image" :src="creator.avatar" />
          <span class="creator__name">{{ creator.name }}</span>
          <!-- <img class="character__user-image" v-if="character.user.image" :src="character.user.image" /> -->
        </div>
      </div>
    </section>
    <section class="derivative-section" style="margin-bottom: 20px;"> 
      <h2>Characters</h2>
      <div class="characters">
        <div class="character" :class="selectedCharacterId == character.id ? 'active':''" @click="selectCharacter(character.id)" v-for="(character, index) in characters" :key="`character-${index}`">
          <img class="character__image" v-if="character.image" :src="character.avatar" />
          <span class="character__name d-none d-sm-inline-block">{{ character.name }}</span>
          <span class="character__name d-sm-none">{{ character.name }}</span>
          <!-- <img class="character__user-image" v-if="character.user.image" :src="character.user.image" /> -->
        </div>
      </div>
    </section>

    <section class="derivative-section" style="margin-bottom: 20px;"> 
      <div class="derivative-menu">
        <div class="derivative-menu-item" @click="resetFilter">Reset filters</div>
        <div class="derivative-menu-item" @click="creatorSortType = 'nameDesc'">Sort Creators (DESC)</div>
        <div class="derivative-menu-item" @click="creatorSortType = 'nameAsc'">Sort Creators (ASC)</div>
      </div>
    </section>

    <section class="derivative-section"> 
      <h2>Doujin Arts</h2>
      <div v-masonry class="masonry-container" transition-duration="0.3s" item-selector=".item"> 
        <div @click="toggleDerivativeDetail(derivative)" v-masonry-tile class="item derivative" v-for="(derivative, index) in derivatives" :key="`derivative-${index}`">
          <img class="derivative__image" v-if="derivative.image" :src="derivative.thumb" />
          <img class="derivative__user-image" v-if="derivative.user.image" :src="derivative.user.avatar" />
          <!-- <div class="derivative__overlay"></div> -->
        </div>
      </div>
      <infinite-loading
        ref="infiniteLoading"
        spinner="waveDots"
        @infinite="infiniteHandler"
        :identifier="infiniteId"
      >
        <span slot="no-more"></span>
        <div slot="no-results"></div>
      </infinite-loading>

      <div class="no-result" v-if="derivatives.length == 0">
        <p>No Result</p>
      </div>
    </section>
    <!-- <button 
      @click="toggleDerivatives"
      class="derivatives-button"
    >
      derivatives
    </button> -->
    <div class="derivative-detail" v-if="!!selectedDerivative"> 
      <div class="derivative-detail__content" @click.stop="">
        <img 
          class="derivative-detail__image" 
          v-if="selectedDerivative.image" 
          :src="selectedDerivative.image" 
        />

        <div class="derivative-detail__body-wrapper">
          <div class="derivative-detail__body" v-if="selectedDerivative.externalLink"> 
            <span class="derivative-detail__body-title">External Link of ART</span>
            <div class="derivative-detail__items">
              <div class="derivative-detail__item">
                <span class="derivative-detail__item-value">{{ selectedDerivative.externalLink }}</span>
              </div>
            </div>
          </div>
          <div class="derivative-detail__body"> 
            <span class="derivative-detail__body-title">ABOUT CREATOR</span>
            <div class="d-flex" style="flex-wrap:wrap"> 

              <div v-if="selectedCreatorId != selectedDerivative.user.id" class="derivative-detail__user" @click="selectCreator(selectedDerivative.user.id)">        
                <img class="derivative-detail__user-image" v-if="selectedDerivative.user.avatar" :src="selectedDerivative.user.avatar" />
                <span class="derivative-detail__user-name">{{ selectedDerivative.user.name }}</span>
              </div>

              <div v-else class="derivative-detail__user" @click="toggleDerivativeDetail('')">
                <img class="derivative-detail__user-image" v-if="selectedDerivative.user.avatar" :src="selectedDerivative.user.avatar" />
                <span class="derivative-detail__user-name">{{ selectedDerivative.user.name }}</span>
              </div>

              <a v-if="!!selectedDerivative.user.twitterUrl" :href="selectedDerivative.user.twitterUrl" target="_blank" class="derivative-detail__user-link-icon derivative-detail__user-link-icon--twitter">        
                <i class="fab fa-twitter"></i>
              </a>
              <a v-if="!!selectedDerivative.user.storeUrl" :href="selectedDerivative.user.storeUrl" target="_blank" class="derivative-detail__user-link-icon derivative-detail__user-link-icon--opensea">        
                <img src="https://storage.googleapis.com/opensea-static/Logomark/Logomark-Blue.png" />
              </a>
              <a v-if="!!selectedDerivative.user.foundationUrl" :href="selectedDerivative.user.foundationUrl" target="_blank" class="derivative-detail__user-link-icon derivative-detail__user-link-icon--foundation">        
                ▲●■
              </a>
            </div>
            <!-- <div class="derivative-detail__items" style="margin-top: 4px;">
              <div class="derivative-detail__item" v-if="selectedDerivative.user.twitterUrl">
                <span class="derivative-detail__item-label">Twitter</span>
                <span class="derivative-detail__item-value">{{ selectedDerivative.user.twitterUrl }}</span>
              </div>
              <div class="derivative-detail__item" v-if="selectedDerivative.user.storeUrl">
                <span class="derivative-detail__item-label">OpenSea</span>
                <span class="derivative-detail__item-value">{{ selectedDerivative.user.storeUrl }}</span>
              </div>
              <div class="derivative-detail__item" v-if="selectedDerivative.user.externalUrl">
                <span class="derivative-detail__item-label">Other Link</span>
                <span class="derivative-detail__item-value">{{ selectedDerivative.user.externalUrl }}</span>
              </div>
            </div> -->

            <template v-if="selectedDerivative.characters.length > 0">
              <span class="derivative-detail__body-title">ABOUT CHARACTERS</span>
              <div class="derivative-detail__items">
                  <div 
                    class="derivative-detail__user" 
                    v-for="(character, index) in selectedDerivative.characters" 
                    :key="`character-${index}`"
                    @click="selectCharacter(character.id)"
                  >        
                    <img class="derivative-detail__user-image" v-if="character.image" :src="character.image" />
                    <span class="derivative-detail__user-name">{{ character.name }}</span>
                  </div>
                <div class="derivative-detail__item">
                  <!-- <span class="derivative-detail__item-label">Characters</span> -->

                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <button 
        @click="toggleDerivativeDetail('')"
        class="derivatives-close-button"
      >X</button>
    </div>
  </div>
</template>

<script>
import axios from 'packs/axios'
export default {
  components: {
    
  },
  data: function() {
    return {
      page: 1,
      totalCount: undefined,
      infiniteId: +new Date(),
      characters: [],
      creators: [],
      derivatives: [],
      intervalId: undefined,
      isDerivativesOpened: false,
      selectedCreatorId: "",
      selectedCharacterId: "",
      selectedDerivative: "",
      creatorSortType: "",
      // modal
      modalMessage: "",
      modalStatus: false,
    }
  },
  created() {
    this.fetchDerivatives();
    this.fetchCharacters();
    this.fetchCreators();

    let uri = window.location.search.substring(1);
    let params = new URLSearchParams(uri);
    this.selectedCharacterId = params.get("character_id")
    this.selectedCreatorId = params.get("creator_id")

    // if(!!this.urlParams.networkType){
    //   this.tokenContent.networkType = this.urlParams.networkType
    // }
    // if(!!this.urlParams.tokenId){
    //   this.tokenContent.tokenIds.push(this.urlParams.tokenId)
    // }
  },
  mounted () {
    // let self = this;
    this.$redrawVueMasonry()
  },
  beforeDestroy: function () {
  },
  computed: {
    price: function(){
      // return this.isWhitelisted ? 0.05 : 0.05;
    },
    selectedCreatorId: function(){
      return this.creators.find(c => c.id == this.selectedCreatorId);
    },
    selectedCharacter: function(){
      return this.characters.find(c => c.id == this.selectedCharacterId);
      // return this.isWhitelisted ? 0.05 : 0.05;
    },
    sortedCreators: function(){
      if(this.creatorSortType == 'nameAsc'){
        return this.creators.sort((a, b) => {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        });
      }
      if(this.creatorSortType == 'nameDesc'){
        return this.creators.sort((a, b) => {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();
          if (nameA > nameB) return -1;
          if (nameA < nameB) return 1;
          return 0;
        });
      }
      return this.creators
    }
  },
  methods: {
    async infiniteHandler() {
      // console.log("hello")
      const self = this;
      const queries = {
        page: this.page,
        character_id: this.selectedCharacterId,
        creator_id: this.selectedCreatorId,
      };
      try {
        const res = await axios.get(`/api/derivatives`, {params: queries})
        this.totalCount = res.data.totalCount;
        this.derivatives.push(...res.data.derivatives);

        if (this.totalCount > this.derivatives.length) {
          self.page += 1;
          self.$refs.infiniteLoading.stateChanger.loaded();
        } else {
          self.$refs.infiniteLoading.stateChanger.complete();
        }
      } catch (e) {
        console.log(e);
      }
    },
    fetchCreators: function(){
      var self = this;
      axios.get(`/api/users`)
      .then(function(res) {
        self.creators = res.data.users
      }).catch((err) => {
        console.log(err)
        // this.isLoading = false;                 
      })
    },
    fetchCharacters: function(){
      var self = this;
      axios.get(`/api/characters`)
      .then(function(res) {
        self.characters = res.data.characters
      }).catch((err) => {
        console.log(err)
        // this.isLoading = false;                 
      })
    },
    fetchDerivatives: function(){
      // var self = this;
      // const queries = {
      //   page: this.page
      // }
      // axios.get(`/api/derivatives`, {params: queries})
      // .then(function(res) {
      //   self.derivatives = res.data.derivatives
      // }).catch((err) => {
      //   console.log(err)
      //   // this.isLoading = false;                 
      // })
    },
    selectCreator: function(creatorId){
      this.page = 1;
      this.derivatives = [];
      this.infiniteId += 1;

      if(this.selectedCreatorId == creatorId){
        this.selectedCreatorId = ""
      }else{
        this.selectedCreatorId = creatorId;
      }
      this.selectedDerivative = ''
    },
    selectCharacter: function(characterId){
      this.page = 1;
      this.derivatives = [];
      this.infiniteId += 1;

      if(this.selectedCharacterId == characterId){
        this.selectedCharacterId = ""
      }else{
        this.selectedCharacterId = characterId;
      }
      this.selectedDerivative = ''
    },
    resetFilter: function(){
      this.page = 1;
      this.derivatives = [];
      this.infiniteId += 1;
      this.selectedDerivative = ''
      this.selectedCreatorId = ""
      this.selectedCharacterId = ""
    },
    // selectCreator: function(creator){
    //   this.page = 1;
    //   this.derivatives = [];
    //   this.infiniteId += 1;


    //   if(this.Id.id == creator.id){
    //     this.Id = ""
    //   }else{
    //     this.Id = creator;
    //   }
    // },
    // selectCharacter: function(character){
    //   this.page = 1;
    //   this.derivatives = [];
    //   this.infiniteId += 1;
    //   if(this.selectedCharacter.id == character.id){
    //     this.selectedCharacter = ""
    //   }else{
    //     this.selectedCharacter = character;
    //   }
    // },
    toggleDerivativeDetail: function(derivative){
      this.selectedDerivative = derivative;
    },
    toggleDerivatives: function(){
      this.isDerivativesOpened = !this.isDerivativesOpened
    },
    // modal
    openModal: function(errorMessage) {
      this.modalMessage = errorMessage;
      this.modalStatus = true;
    },
    closeModal: function() {
      this.modalStatus = false;
    },
  },
}
</script>

<style scoped lang="scss"> 
  .no-result{
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    // background-color: #000;
    border-radius: 8px;
    p{
      font-weight: 600;
      font-size: 20px;
    }
  }
  .characters{
    // overflow-y: scroll;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  .character{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 5px 12px 4px 5px;
    text-align: center;
    background-color: rgba(255,255,255,.1);
    border-radius: 4px;
    margin-right: 8px;
    margin-bottom: 8px;
    cursor: pointer;
    @media(max-width: 567px){
      font-size: 11px;
      padding: 0;
      padding-right: 10px;
    }
    &.active{
      background-color: lightseagreen;
    }
    &__image{
      margin-right: 4px;
      height: 30px;
      width: 30px;
      border-radius: 4px;
      object-fit: cover;
      background-color: #444;
      @media(max-width: 567px){
        height: 20px;
        width: 20px;
        margin-right: 4px;
      }
    }
    &__name{
      font-weight: 600;
    }
  }
  .creators{
    overflow-y: scroll;
    display: flex;
    align-items: flex-start;
  }
  .creator{
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    padding: 6px;
    text-align: center;
    cursor: pointer;
    @media(max-width: 567px){
      padding: 2px;
    }
    &__image{
      margin-bottom: 4px;
      height: 80px;
      width: 80px;
      border-radius: 50%;
      object-fit: cover;
      border: 4px solid transparent;
      &.active{
        border: 4px solid lightseagreen;
        padding: 4px;
      }
      @media(max-width: 567px){
        margin-bottom: 4px;
        height: 60px;
        width: 60px;
      }
    }
    &__name{
      font-weight: 600;
      font-size: 11px;
      word-break: break-all;
      max-width: 62px;
      margin: 0 auto;
    }
  }
  .derivative-section{
    margin-bottom: 8px;
    h2{
      font-weight: 600;
      font-size: 20px;
    }
  }
  .derivative-detail{
    // background: linear-gradient(rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.2) 30%, rgba(0, 0, 0, 0.2) 70%, rgba(0, 0, 0, 0.7) 100%);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,.8);
    z-index: 1000;
    overflow-y: scroll;
    color: #000;
    padding: 80px;
    @media(max-width: 567px){
      padding: 12px;
      padding-top: 100px;
    }
    &__content{
      max-width: 1200px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
    }
    &__image{
      width: 100%;
      // border-radius: 12px;
      background-color: #000;
      // margin-bottom: 20px;
      @media(max-width: 567px){
        margin-bottom: 12px;
      }
    }
    &__items{
      display: flex;
      flex-wrap: wrap;
      &:not(:last-child){
        margin-bottom: 20px;
      }
    }
    &__item{
      margin-top: 12px;
      margin-right: 20px;
    }
    &__item-label{
      font-size: 11px;
      display: block;
      line-height: 4px;
      color: #111;
    }
    &__item-value{
      font-size: 14px;
    }
    &__user{
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      margin-bottom: 8px;
      margin-right: 4px;
      background-color: #eee;
      padding: 8px 20px 8px 8px;
      border-radius: 8px;
      &--disable{
        background-color: transparent;
      }
    }
    &__user-link-icon{
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      margin-bottom: 8px;
      margin-right: 4px;
      color: #fff!important;
      font-size: 20px;
      background-color: skyblue;
      padding: 8px 12px;
      border-radius: 8px;
      font-weight: 600;
      &--twitter{
        background-color: skyblue;
      }
      &--opensea{
        background-color: #2583D6;
        padding: 0 4px;
        img{
          width: 36px;
        }
      }
      &--foundation{
        background-color: black;
        padding: 4px 8px;
      }
    }
    &__user-image{
      height: 24px;
      width: 24px;
      object-fit: cover;
      border-radius: 50%;
      margin-right: 8px;
    }
    &__user-name{
      font-weight: 600;
    }
    &__body-title{
      font-family: "Babas", cursive;
      color: lightseagreen;
      display: block;
    }
    &__body-wrapper{
      min-width: 260px;
    }
    &__body{
      background-color: rgba(255,255,255,1);
      // border: 7px double #999;
      padding: 20px;
      min-width: 260px;
      margin: 0 0 20px;
      // border-radius: 20px;
      @media(max-width: 567px){
        margin: 0 0 12px;
      }
    }
    &__item{

    }
    &__item-title{
      font-weight: 600;
      font-size: 11px;
    }
    &__item-value{
      border-bottom: 1px solid;
      word-break: break-all;
    }
  }
  .derivative{
    position: relative;
    width: 25%;
    @media(max-width: 920px){
      width: 33.3%;
    }
    @media(max-width: 567px){
      width: 50%;
    }
    &__image{
      cursor: pointer;
      padding: 8px;
      width: 100%;
      border-radius: 12px;
      // background-color: #111;
      @media(max-width: 567px){
        padding: 4px;
      }

      &:hover{
        opacity: .9;
      }
    }
    &__user-image{
      height: 28px;
      width: 28px;
      object-fit: cover;
      border-radius: 50%;
      position: absolute;
      bottom: 12px;
      left: 12px;
      @media(max-width: 567px){
        bottom: 8px;
        left: 8px;
      }
    }
    &__overlay{
      transition: .1s;
      opacity: 0;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.2) 30%, rgba(0, 0, 0, 0.2) 70%, rgba(0, 0, 0, 0.7) 100%);
      &:hover{
        opacity: 1;
      }
    }
  }
  .derivatives-close-button{
    // color: #060606;
    display: inline-block;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    position: fixed;
    right: 20px;
    top: 20px;
    background-color: lightseagreen;
    color: #fff;
    font-weight: 600;
  }
  .modal-desc{
    color: #000;
  }

  .derivative-menu{
    display: flex;
    &-item{
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 5px 12px 4px 5px;
      text-align: center;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 4px;
      margin-right: 8px;
      margin-bottom: 8px;
      cursor: pointer;
    }
  }
</style>
