import Vue from 'vue';

// component (増えすぎたらフロントをnuxt.jsに分けることを検討)
import UnlockableTokenContents from '../pages/unlockables/index.vue'
import WalletLogin from 'packs/components/utility/wallet-login.vue'
import CountdownTimer from "packs/components/utility/countdown-timer.vue";
import MintButlers from "../pages/tokens/mint-butlers.vue";
import MintButlersDev from "../pages/tokens/mint-butlers-dev.vue";
import Derivatives from "../pages/derivatives/index.vue";
import MintMaids from "../pages/tokens/mint-maids.vue"
import Characters from "../components/characters/list.vue";
import Creators from "../components/creators/list.vue";
import Roadmaps from "../components/lp/roadmaps.vue";
import Links from "../components/lp/menu.vue";

// ライブラリ
import vuetify from '../plugins/vuetify' 
import TurbolinksAdapter from 'vue-turbolinks'
import VueConfetti from 'vue-confetti'
import VueI18n from 'vue-i18n'
import { VueMasonryPlugin } from 'vue-masonry'
import InfiniteLoading from 'vue-infinite-loading';

// 翻訳ファイル
import i18nData from '../locale/main.json'

Vue.use(TurbolinksAdapter)
Vue.use(VueConfetti)
Vue.use(VueI18n)
Vue.use(VueMasonryPlugin)
Vue.use(InfiniteLoading);

// もっと良い感じに描きたい
const locale = location.pathname.includes('/ja/') ? 'ja' : 'en'
const i18n = new VueI18n({
  locale: locale,
  messages: i18nData,
})

document.addEventListener('turbolinks:load', () => {
  const app = new Vue({
    vuetify,
    i18n,
    el: '#application-vue-js',
    data: {
    },
    components: {
      WalletLogin,
      UnlockableTokenContents,
      CountdownTimer,
      MintButlers,
      MintButlersDev,
      Derivatives,
      MintMaids,
      Characters,
      Roadmaps,
      Creators,
      Links
    }
  })
})