<template>
  <div class="spinner-overlay">
    <div id="lottie" class="lottie-animation--treasurebox" ref="lottie"></div>
    <div class="text-center" v-if="!!textHtml" v-html="textHtml"></div>
    <div class="text-center" v-else>Loading...</div>
  </div>
</template>

<script>
import lottie from 'lottie-web';
import animationData from '../../assets/lottie/26921-treasure-box-magic.json';

export default {
  props: ['textHtml'],
  data: function () {
    return {
    }
  },
  mounted() {
    lottie.loadAnimation({
      container: this.$refs.lottie, // document.getElementbyId('lottie') などでも OK
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData,
    });
  },
}
</script>

<style scoped>
  .spinner-overlay{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,.7);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 1100;
    font-weight: 600;
  }
  .lottie-animation--treasurebox{
    max-width: 420px;
  }
</style>
