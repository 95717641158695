<template>
  <div> 
    <div class="mb-3 text-center">
      <span @click="unlockWallet" class="unlock-wallet-btn" id="unlock-wallet-btn">
        {{ btnTitle }}
      </span>
    </div>
    <modal v-if="showModal" @close="showModal = false" :modalSuccess="modalSuccess" @close-modal="closeModal">
      <div class="modal-body" slot="body">
        <div class="modal-messages">
            {{ modalMessage }}
        </div>
      </div>
      <div v-if="!!modalFooterHtml" slot="footer" class="modal-btns modal-btns--flex-center">
        <template>
          <div v-html="modalFooterHtml"></div>
        </template>
      </div>
    </modal>
  </div>
</template>

<script>
import axios from 'packs/axios'
// import Web3 from 'web3'
import Modal from 'packs/components/modal/modal.vue'
// import metamaskImage from 'packs/components/spl/spl_images/metamask.png'

export default {
  components: {
    Modal
  },
  data: function() {
    return {
      userAgent: "",
      walletAddress: "",
      type: "", //sign_upかlogInかhomeSignUpか
      // metamaskImage: metamaskImage,
      // metamask: "https://metamask.io/",
      
      showModal: false,
      modalType: "normal",
      modalSuccess: false,
      modalMessage: "",
      modalFooterHtml: ""
    }
  },
  created() {
    this.userAgent = window.navigator.userAgent.toLowerCase();
  },
  mounted () {
  },
  computed: {
    btnTitle: function (){
      return "Login with Metamask"
      // if(this.type == "signUp"){
      //   let title = "Sign up with Ethereum wallet"
      //   return title
      // }else if(this.type == "logIn"){
      //   let title = "Login with Ethereum wallet"
      //   return title
      // }else{
      //   let title = "Start playing"
      //   return title
      // }
    },
    // isHomeSignUp: function (){
    //   return this.type == "homeSignUp";
    // },
    guessUserAgent: function() {
      let self = this;

      if(self.userAgent.indexOf('iphone') != -1 || self.userAgent.indexOf('ipad') != -1){
        return "iphone"
      }else if(self.userAgent.indexOf('android') != -1){
        return "android"
      }else{
        return "pc"
      }
    },
  },
  methods: {
    unlockWallet: async function(){
      var self = this;

      //web3がない状況(シークレットブラウザーや、ウォレットアプリじゃない状況),
      //もしくはメタマスクのない状況(window.ethereumがない状況)ならその注意文を出してreturn
      if (typeof window.ethereum === 'undefined'){
        console.log("??")
        self.showModal = true;
        self.modalType = "normal";
        self.modalSuccess = false;
        self.modalMessage = "Network error. Please connect your wallet. (Metamask is recommended)";
        self.modalSubMessages = "";
        return
      }

      //本番環境ではmainnetを、stagingやlocalではropstenを使うよう指定
      // console.log(ethereum.networkVersion)
      // if(ethereum.networkVersion != 1) {
      //   self.showModal = true;
      //   self.modalType = "normal";
      //   self.modalSuccess = false;

      //   self.modalMessage = "Please use mainnet";
      //   self.modalSubMessages = "";
      //   return
      // }

      this.connectAccounts();
    },
    connectAccounts: async function(){
      self = this;

      const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
      const account = accounts[0];

      console.log(account)
      if(typeof account == 'undefined'){
        self.showModal = true;
        self.modalType = "normal";
        self.modalSuccess = false;
        self.modalMessage = "Error.";
        self.modalSubMessages = "";
        return
      }else{
        // self.signAndRegister(account);
      }
    },
    // signAndRegister: function(walletAddress){
    //   self = this;

    //   //web3をnewする metamaskであろうがその他であろうが、同じバージョンのweb3を使う
    //   if(typeof web3 != 'undefined'){
    //     window.web3 = new Web3(web3.currentProvider);
    //   }else if(typeof window.ethereum != 'undefined'){
    //     window.web3 = new Web3(window.ethereum);
    //   }

    //   //署名
    //   web3.eth.personal.sign("Agree to Terms. https://cryptomaids.herokuapp.com/terms", walletAddress)
    //   .then(function(result){
    //     let params = { signed_data: result, address: walletAddress, type: self.type }
    //     axios.post("/api/wallet_login", params)
    //     .then(function(res) {
    //       console.log(res)
    //       if(res.data.error_message){
    //         self.showModal        = true;
    //         self.modalType        = "normal";
    //         self.modalSuccess     = true;
    //         self.modalMessage    = res.data.error_message;
    //         self.modalSubMessages = "";
    //         return
    //       }
    //       window.location.href = "/"        
    //     })
    //     .catch(function(error) {
    //       self.showModal = true;
    //       self.modalType = "normal";
    //       self.modalSuccess = false;
    //       self.modalMessage = "Error";
    //       self.modalSubMessages = "";
    //     })
    //   })
    // },
    closeModal: function() {
      this.showModal = false;
    }
  },
}
</script>

<style scoped>
.test{
  color: red;
}
</style>
