<template>
  <div class="container">
      <div class="row">
        <div class="col-12 col-md-4 order-1 order-md-2 pl-md-5">
          <div class="unlockable-token-content-group"> 
            <div style=""> 
              <button v-if="!isUnlocked" @click="unlockWallet" class="unlock-content-btn">
                <i class="fas fa-key"></i>
                {{$t("tokenContent.unlocks.unlockBonusContent")}}
              </button>
              <span v-else class="unlock-content-btn--unlocked">
                <span style="margin:0 4px 0 0; font-size: 20px">🥳</span>
                {{$t("tokenContent.unlocks.unlocked")}}
              </span>

              <button v-if="!!tokenContentBasic.isDemo && !isUnlocked" @click="unlockDemo" class="edit-unlock-content-btn" style="margin-bottom: 12px;">
                {{$t("tokenContent.unlocks.unlockDemoContent")}}
              </button>

              <a class="edit-unlock-content-btn" v-if="tokenContentBasic.user && currentUserWalletAddress == tokenContentBasic.user.address" :href="`/token_contents/${tokenContentId}/edit`"> 
                {{$t("tokenContent.unlocks.editContent")}}
              </a> 
            </div>
            <div class="unlockable-token-content__section">
              <p class="unlockable-token-content__title">{{ tokenContentBasic.title }}</p>
            </div>
            <div class="unlockable-token-content__section" style="margin-bottom: 40px;">
              <p class="unlockable-token-content__text" style="min-height: 40px;" v-html="tokenContentBasic.descriptionHtml"></p>
            </div>
            <div class="unlockable-token-content__section">
              <p class="unlockable-token-content__description">Collection: <a :href="`/collections/${tokenContentBasic.contractDatum.address}`" style="border-bottom: 1px solid #fff"  data-turbolinks="false">{{ tokenContentBasic.contractDatum.name }}</a></p>
            </div>
            <div class="unlockable-token-content__section">
              <p class="unlockable-token-content__description">Network: {{ tokenContentBasic.contractDatum.networkTypeI18n }}</p>
            </div>

            <div class="unlockable-token-content__section mb-5">
              <p class="unlockable-token-content__description">
                  <span v-if="tokenContentBasic.targetType == 'all_tokens'">{{$t("tokenContent.unlocks.allTokens")}}</span>
                  <template v-for="(tokenId, index) in tokenContentBasic.tokenIds">
                    <a
                      class="unlockable-token-content__tokenid"
                      :href="`/tokens/${tokenContentBasic.contractDatum.networkId}/${tokenContentBasic.contractDatum.address}/${tokenId}`"
                      :key="`token-id-${index}`"
                    >
                      <v-chip
                        label
                      >
                        #{{ tokenId }}
                      </v-chip>
                    </a>
                  </template>
              </p>
            </div>

            <div class="unlockable-token-content__section">
              <div class="unlockable-token-content__description unlockable-token-content__description--break-all">
                <span class="d-block mb-2">Created by </span>
                <div class=""> 
                  <a class="unlockable-token-content__user" :href="`/profiles/${tokenContentBasic.user.address}`"> 
                    <img v-if="!!tokenContentBasic.user.image" :src="tokenContentBasic.user.image">
                    <span v-if="tokenContentBasic.user.name">
                      {{ tokenContentBasic.user.name }}
                    </span>
                    <span v-else>
                      {{ tokenContentBasic.user.address }}
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div class="col-12 col-md-8 order-2 order-md-1">
          <div class=""> 
            <template v-if="isTokenContentPresence" >
                <div class="unlocked-content-group">
                    <div class="unlocked-content-not-exist">
                        {{$t("tokenContent.unlocks.noContent")}}
                    </div>
                </div>
            </template>

            <!-- <%# video -->
            <template v-if="!!tokenContentPresence.video" >
                <p class="unlocked-content-group__label"><i class="fas fa-film"></i>{{$t("general.video")}}</p>

                <div v-if="!!tokenContent.video" class="unlocked-content-group"> 
                    <video controls poster="" class="unlocked-content-group__video">
                        <source :src="tokenContent.video" type="video/mp4">
                    </video>
                </div>
                <div v-else class="unlocked-content-group"> 
                  <div @click="unlockWallet" class="unlocked-content-placeholder"> 
                    <span class="unlocked-content-icon">
                      <v-icon
                          small_tokens
                        >
                        mdi-lock
                      </v-icon>
                    </span>
                  </div>
                </div>
            </template>

            <!-- <%# audio -->
            <template v-if="!!tokenContentPresence.audio" >
                <p class="unlocked-content-group__label"><i class="fas fa-headphones-alt"></i>{{$t("general.audio")}}</p>

                <div v-if="!!tokenContent.audio" class="unlocked-content-group"> 
                  <audio :src="tokenContent.audio" class="unlocked-content-group__audio" controls />
                </div>
                <div v-else class="unlocked-content-group"> 
                  <div @click="unlockWallet" class="unlocked-content-placeholder"> 
                    <span class="unlocked-content-icon">
                      <v-icon
                          small_tokens
                        >
                        mdi-lock
                      </v-icon>
                    </span>
                  </div>
                </div>
            </template>



            <!-- <%# text -->
            <template v-if="!!tokenContentPresence.textContent" >
                <p class="unlocked-content-group__label"><i class="fas fa-align-left"></i>{{$t("general.text")}}</p>
                <div v-if="!!tokenContent.textContent" class="unlocked-content-group"> 
                    <div class="unlocked-content__section">
                        <p class="unlocked-content__text-content" v-html="tokenContent.textContentHtml"></p>
                    </div>
                </div>
                <div v-else class="unlocked-content-group"> 
                  <div @click="unlockWallet" class="unlocked-content-placeholder"> 
                    <span class="unlocked-content-icon">
                      <v-icon
                          small_tokens
                        >
                        mdi-lock
                      </v-icon>
                    </span>
                  </div>
                </div>
            </template>

            <!-- <%# image -->
            <template v-if="!!tokenContentPresence.images" >
                <p class="unlocked-content-group__label"><i class="far fa-image"></i>{{$t("general.image")}}</p>

                <div v-if="!!tokenContent.images.length > 0" class="unlocked-content-group"> 
                    <template v-for="(image, index) in tokenContent.images" >
                        <img :src="image.imageUrl" :key="index" class="unlocked-content-group__image">
                    </template>
                </div>
                <div v-else class="unlocked-content-group"> 
                  <div @click="unlockWallet" class="unlocked-content-placeholder"> 
                    <span class="unlocked-content-icon">
                      <v-icon
                          small_tokens
                        >
                        mdi-lock
                      </v-icon>
                    </span>
                  </div>
                </div>
            </template>

            <!-- <%# pdfUrl -->
            <template v-if="!!tokenContentPresence.pdf" >
                <p class="unlocked-content-group__label">PDF</p>

                <div v-if="!!tokenContent.pdf" class="unlocked-content-group"> 
                    <a :href="tokenContent.pdf" download class="download-btn">DOWNLOAD</a>
                </div>
                <div v-else class="unlocked-content-group"> 
                  <div @click="unlockWallet" class="unlocked-content-placeholder"> 
                    <span class="unlocked-content-icon">
                      <v-icon
                          small_tokens
                        >
                        mdi-lock
                      </v-icon>
                    </span>
                  </div>
                </div>
            </template>

            <!-- <%# epub -->
            <template v-if="!!tokenContentPresence.epub" >
                <p class="unlocked-content-group__label">{{$t("general.epub")}}</p>

                <div v-if="!!tokenContent.epub" class="unlocked-content-group"> 
                  <a :href="tokenContent.epub" download class="download-btn">DOWNLOAD</a>
                </div>
                <div v-else class="unlocked-content-group"> 
                  <div @click="unlockWallet" class="unlocked-content-placeholder"> 
                    <span class="unlocked-content-icon">
                      <v-icon
                          small_tokens
                        >
                        mdi-lock
                      </v-icon>
                    </span>
                  </div>
                </div>
            </template>

            <!-- <%# psd -->
            <template v-if="!!tokenContentPresence.psd" >
                <p class="unlocked-content-group__label">Photoshop Data</p>

                <div v-if="!!tokenContent.psd" class="unlocked-content-group"> 
                  <a :href="tokenContent.psd" download class="download-btn">DOWNLOAD</a>
                </div>
                <div v-else class="unlocked-content-group"> 
                  <div @click="unlockWallet" class="unlocked-content-placeholder"> 
                    <span class="unlocked-content-icon">
                      <v-icon
                          small_tokens
                        >
                        mdi-lock
                      </v-icon>
                    </span>
                  </div>
                </div>
            </template>

            <!-- <%# ai -->
            <template v-if="!!tokenContentPresence.ai" >
                <p class="unlocked-content-group__label">Illustrator Data</p>

                <div v-if="!!tokenContent.ai" class="unlocked-content-group"> 
                  <a :href="tokenContent.ai" download class="download-btn">DOWNLOAD</a>
                </div>
                <div v-else class="unlocked-content-group"> 
                  <div @click="unlockWallet" class="unlocked-content-placeholder"> 
                    <span class="unlocked-content-icon">
                      <v-icon
                          small_tokens
                        >
                        mdi-lock
                      </v-icon>
                    </span>
                  </div>
                </div>
            </template>


            <treasurebox-with-overlay v-if="!!isLoading" textHtml="Unlocking bonus content... Please wait." />
          </div>
        </div>
      </div>
      <modal v-if="showModal" @close="showModal = false" :modalSuccess="modalSuccess" @close-modal="closeModal">
        <div class="modal-body" slot="body">
          <div class="modal-messages">
              {{ modalMessage }}
          </div>
        </div>
        <div v-if="!!modalFooterHtml" slot="footer" class="modal-btns modal-btns--flex-center">
          <template>
            <div v-html="modalFooterHtml"></div>
          </template>
        </div>
      </modal>
      <v-snackbar
        v-model="snackbar.show"
        top
        right
        text
        :color="snackbar.color"
        :timeout="snackbar.timeout"
      >
        <div @click="snackbar.show = false"> 
          {{ snackbar.text }}
        </div>
      </v-snackbar>

  </div>
</template>

<script>
import axios from 'packs/axios'
// import Web3 from 'web3'
import TreasureboxWithOverlay from 'packs/components/utility/treasurebox-with-overlay.vue'
import Modal from 'packs/components/modal/modal.vue'


// presence api
// unlocks api

export default {
  components: {
    TreasureboxWithOverlay,
    Modal
  },
  data: function() {
    return {
      isLoading: false,
      isUnlocked: false,
      tokenContentId: "",
      currentUserWalletAddress: "",
      tokenContentPresence: {
        images: false,
        video: false,
        text: false,
        audio: false,
        pfd: false,
        epub: false,
        ai: false,
        psd: false,
      },
      tokenContent: {
        images: [],
        video: "",
        text: "",
        audio: "",
        pfd: "",
        epub: "",
        ai: "",
        psd: "",
      },
      tokenContentBasic: {
        description: "",
        descriptionHtml: "",
        title: "",
        thumbnailUrl: "",
        targetType: "",
        contract: "",
        tokenIds: "",
        user: {
          address: "",
        },
        contractDatum: {
          name: ""
        },
        isDemo: "",
      },
      snackbar: {
        show: false,
        text: '',
        timeout: 5000,
        color: "primary",
      },
      showModal: false,
      modalType: "normal",
      modalSuccess: false,
      modalMessage: "",
      modalFooterHtml: ""
    }
  },
  computed: {
    isTokenContentPresence(){
      !this.tokenContentPresence.images && !this.tokenContentPresence.video && !this.tokenContentPresence.text && !this.tokenContentPresence.audio && !this.tokenContentPresence.pfd && !this.tokenContentPresence.epub && !this.tokenContentPresence.psd && !this.tokenContentPresence.ai
    }
  },
  mounted() {  
    const element = document.getElementById("unlock-token-content");
    const data = JSON.parse(element.getAttribute('data'));
    this.tokenContentId = data.id;
    this.currentUserWalletAddress = data.currentUserWalletAddress;
    this.fetchTokenContent();
  },
  methods: {
    fetchTokenContent: function(){
      var self = this;
      axios.get(`/api/token_content_presences/${this.tokenContentId}`)
      .then(function(res) {
        self.tokenContentPresence = res.data.tokenContentPresence
      }).catch((err) => {
        console.log(err)
        // this.isLoading = false;                 
      })

      axios.get(`/api/token_content_basics/${this.tokenContentId}`)
      .then(function(res) {
        self.tokenContentBasic = res.data.tokenContentBasic
      }).catch((err) => {
        console.log(err)
        // this.isLoading = false;                 
      })
    },
    // signWallet: function(walletAddress){
    //   const self = this;

    //   //web3をnewする metamaskであろうがその他であろうが、同じバージョンのweb3を使う
    //   if(typeof web3 != 'undefined'){
    //     window.web3 = new Web3(web3.currentProvider);
    //   }else if(typeof window.ethereum != 'undefined'){
    //     window.web3 = new Web3(window.ethereum);
    //   }

    //   //署名
    //   web3.eth.personal.sign("Do you agree to our terms and unlock your bonus content. https://cryptomaids.herokuapp.com/terms", walletAddress)
    //   .then(function(result){
    //     let params = { id: self.tokenContentId, signed_data: result, address: walletAddress, type: self.type }
    //     self.isLoading = true;
    //     setTimeout(
    //       function(){
    //         self.unlockTokenContent(params);
    //       }
    //     ,5000);
        
    //   })
    // },
    unlockTokenContent(params){
        axios.post(`/api/unlock_token_contents`, params)
        .then(function(res) {
          console.log(res)
          self.tokenContent = res.data.tokenContent

          self.isUnlocked = true;
          self.isLoading = false;
          self.$confetti.start();
          setTimeout(
            function(){
              self.$confetti.stop();
            }
          ,3000);
        })
        .catch(function(err) {
          let msg = 'Failed, Invalid Request'
          if(err.response.status == 401 || err.response.status == 404){
            msg = self.$i18n.t("demos.errors.unauthorizedUnlock")
          }
          if(err.response.status == 403){
            msg = self.$i18n.t("demos.errors.forbiddenUnlock")
          }
          self.$set(self.snackbar, 'show', true)
          self.$set(self.snackbar, 'text', msg)
          self.$set(self.snackbar, 'color', 'alert')
          self.isLoading = false;
        })
    },
    unlockDemo(){
        this.isLoading = true
        let params = { id: this.tokenContentId }
        const self = this;
        axios.post(`/api/unlock_demo_token_contents`, params)
        .then(function(res) {
          console.log(res)
          self.tokenContent = res.data.tokenContent

          self.isUnlocked = true;
          self.isLoading = false;
          self.$confetti.start();
          setTimeout(
            function(){
              self.$confetti.stop();
            }
          ,3000);
        })
        .catch(function(err) {
          let msg = 'Failed, Invalid Request'
          if(err.response.status == 401){
            msg = self.$i18n.t("demos.errors.unauthorizedUnlock")
          }
          self.$set(self.snackbar, 'show', true)
          self.$set(self.snackbar, 'text', msg)
          self.$set(self.snackbar, 'color', 'alert')
          self.isLoading = false;
        })
    },
    unlockWallet: async function(){
      var self = this;

      //web3がない状況(シークレットブラウザーや、ウォレットアプリじゃない状況),
      //もしくはメタマスクのない状況(window.ethereumがない状況)ならその注意文を出してreturn
      if (typeof window.ethereum === 'undefined'){
        self.showModal = true;
        self.modalType = "normal";
        self.modalSuccess = false;
        self.modalMessage = "Network error. Please connect your wallet. (Metamask is recommended)";
        self.modalSubMessages = "";
        return
      }

      //本番環境ではmainnetを、stagingやlocalではropstenを使うよう指定
      // console.log(ethereum.networkVersion)
      // if(ethereum.networkVersion != 1) {
      //   self.showModal = true;
      //   self.modalType = "normal";
      //   self.modalSuccess = false;

      //   self.modalMessage = "";
      //   self.modalSubMessages = "";
      //   return
      // }

      this.connectAccounts();
    },
    connectAccounts: async function(){
      self = this;

      const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
      const account = accounts[0];

      if(typeof account == 'undefined'){
        self.showModal = true;
        self.modalType = "normal";
        self.modalSuccess = false;
        self.modalMessage = "Network error. Please connect your wallet. (Metamask is recommended)";
        self.modalSubMessages = "";
        return
      }else{
        self.signWallet(account);
      }
    },
    closeModal: function() {
      this.showModal = false;
    }
  }
}
</script>

<style scoped lang="scss">
.thumbbail{
  background-color: #333;
  border-radius: 8px;
  height: 200px;
  background-size: cover;
  background-position: center;
}
.form-group-title{
  color: #060606;
  font-size: 16px;
  font-weight: 600;
}
.token-content-form-group{
  margin-bottom: 12px;
}
.token-content-form-group--clickable{
  cursor: pointer;
  i{
    padding: 8px;
    border-radius: 50%;
    background-color: #333;
    font-size: 12px;
  }
}
 .modal-footer > div{
   width: 100%;
 }
 .modal-btn{
   display: block;
 }
 .scta-link{
    margin-top: 8px;
    display: inline-block;
    border-bottom: 1px solid rgb(153, 153, 153);
    font-size: 12px;
    color: #666;
    margin-left: 16px;
 }
 .file-input-wrapper{
   position: relative;
 }
 .remove-file-input-btn{
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 40%;
  transform: translate(0, -60%);
 }
 .vimeo-embed{position:relative;width:100%;height:0;padding-bottom:56.27198%;}
 .vimeo-embed iframe{position:absolute;top:0;left:0;width:100%;height:100%;}

 button{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #aaa;
 }

  .add-file-btn{
    cursor: pointer;
    padding: 6px;
    border-radius: 4px;
    font-size: 13px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  .btn-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .invalid-content{
    color: lightpink;
    font-size: 14px;
  }
  .token-content__contract-description{
    margin-bottom: 20px;
  }
  .token-content__contract-link-divider{
    display: inline-block;
    padding: 0 8px;
    color: #ccc;
  }
  a.token-content__contract-link,
  .token-content__contract-link{
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    color: #ccc;
    // border-bottom: 1px solid #ccc;
  }
  .form-group-title-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    i{
      margin-bottom: 4px;
    }
  }
  .unlocked-content-placeholder{
    background-color: #181818;
    padding: 20px;
    margin: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    min-height: 400px;
    @media(max-width: 567px){
      min-height: 200px;
    }
  }
  .unlocked-content-icon{
    background-color: #111;
    padding: 16px;
    border-radius: 50%;
  }
  .unlocked-content__section{
    min-height: 120px;
    @media(max-width: 567px){
      min-height: 120px;
    }
  }
  .unlock-content-btn{
    display: inline-flex;
    font-weight: 600;
    border-radius: 8px;
    background-color: aquamarine;
    padding: 4px 20px 4px 4px;
    color: #000;
    width: 100%;
    margin-top: 24px;
    margin-bottom: 12px;
    // display: inline-flex;
    // font-weight: 600;
    // border-radius: 50px;
    // background-color: #333;
    // padding: 4px 20px 4px 4px;
    i{
      font-size: 20px;
      padding: 10px;
      // font-size: 20px;
      // background-color: #111;
      // padding: 16px;
      // margin-right: 8px;
      // border-radius: 50%;
    }
    &--unlocked{
      cursor: default;
      display: inline-flex;
      font-weight: 600;
      border-radius: 8px;
      color: #060606;
      width: 100%;
      margin-top: 24px;
      margin-bottom: 12px;
      text-align: left;
      justify-content: flex-start;
    }
  }
  .edit-unlock-content-btn{
      display: inline-flex;
      justify-content: center;
      font-weight: 600;
      border-radius: 8px;
      background-color: #333;
      padding: 12px 20px;
      color: #060606;
      width: 100%;
      text-align: center;
  }
  .download-btn{
    text-align: center;
    background-color: #333;;
    display: inline-block;
    padding: 16px 54px;
    border-radius: 8px;
    margin: 0 auto;
    min-width: 320px;
    width: 100%;
    letter-spacing: 2px;
    font-size: 14px;
  }
</style>
