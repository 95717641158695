<template>
  <div class="container">
    <section class="character-sections">
      <section class="character-section" style="margin-bottom: 20px;"> 
        <div class="creators">
          <a class="creator" :class="selectedCreator.id == creator.id ? 'active':''" :href="`/tachiyomi?creator_id=${creator.id}`" target="_blank" v-for="(creator, index) in creators" :key="`creator-${index}`">
            <img class="creator__image" v-if="creator.image" :src="creator.avatar" />
            <span class="creator__name">{{ creator.name }}</span>
          </a>
        </div>
      </section>
    </section>

    <!-- <v-dialog
      v-model="dialog"
      width="600px"
    >
      <v-card>
        <section class="character-detail-section"> 
          <template v-if="!!this.selectedCharacter">
            <h2>{{ this.selectedCharacter.name }}</h2>

            <div class="character-detail__items">
              <div v-if="!!this.selectedCharacter.height" class="character-detail__item character-detail__item--horizon">
                <span class="character-detail__label">Height: </span>
                <p>
                  {{ this.selectedCharacter.height }}
                </p>
              </div>

              <div v-if="!!this.selectedCharacter.birthday" class="character-detail__item character-detail__item--horizon">
                <span class="character-detail__label">Birthday: </span>
                <p>
                  {{ this.selectedCharacter.birthday }}
                </p>
              </div>

              <div v-if="!!this.selectedCharacter.role" class="character-detail__item character-detail__item--horizon">
                <span class="character-detail__label">Weapon/Role: </span>
                <p v-if="!!this.locale == 'en'">
                  {{ this.selectedCharacter.role }}
                </p>
                <p v-else>
                  {{ this.selectedCharacter.roleJa }}
                </p>
              </div>
            </div>


            <div v-if="!!this.selectedCharacter.quote" class="character-detail__item">
              <span class="character-detail__label">Quote: </span>
              <p v-if="!!this.locale == 'en'">
                {{ this.selectedCharacter.quote }}
              </p>
              <p v-else>
                {{ this.selectedCharacter.quoteJa }}
              </p>
            </div>

            <div v-if="!!this.selectedCharacter.description" class="character-detail__item">
              <span class="character-detail__label">Description: </span>
              <p v-if="!!this.locale == 'en'">
                {{ this.selectedCharacter.description }}
              </p>
              <p v-else>
                {{ this.selectedCharacter.descriptionJa }}
              </p>
            </div>
        
            <div class="derivatives" transition-duration="0.3s" item-selector=".item"> 
              <div @click="toggleDerivativeDetail(derivative)" :style="`background-image: url(${derivative.thumb})`" class="item derivative" v-for="(derivative, index) in derivatives" :key="`derivative-${index}`">
                <img class="derivative__user-image" v-if="derivative.user.image" :src="derivative.user.avatar" />
              </div>
            </div>
          </template>

          <div class="no-result" v-if="derivatives.length == 0">
            <p>No Result</p>
          </div>
        </section>
      </v-card>
    </v-dialog> -->
  </div>
</template>

<script>
import axios from 'packs/axios'
export default {
  components: {
    
  },
  data: function() {
    return {
      page: 1,
      dialog: false,
      totalCount: undefined,
      characters: [],
      creators: [],
      derivatives: [],
      intervalId: undefined,
      isDerivativesOpened: false,
      selectedCreator: "",
      selectedCharacter: "",
      selectedDerivative: "",
      // modal
      modalMessage: "",
      modalStatus: false,
      locale: "en",

      adminNames: ["NeoCornerJr", "Yuki", "Rina", "Suzuki san", "Cliff"]
    }
  },
  created() {
    // this.fetchDerivatives();
    this.fetchCharacters();
    this.fetchCreators();

    // this.buildIMX();
    this.locale = location.pathname.includes('/ja_home') ? 'ja' : 'en'
    // this.$i18n.locale = locale
    // this.currentTime = parseInt(Date.now() / 1000)

  },
  mounted () {
    // let self = this;
    this.$redrawVueMasonry()
  },
  beforeDestroy: function () {
  },
  computed: {
    price: function(){
      // return this.isWhitelisted ? 0.05 : 0.05;
    },
    maids: function(){
      return this.characters.filter(function (c) {      
          return c.collectionType === "cryptomaids" 
      })
    },
    adminMaids: function(){
      var self = this
      return this.characters.filter(function (c) {      
          return c.collectionType === "cryptomaids" && self.adminNames.includes(c.name)
      })
    },
    normalMaids: function(){
      var self = this
      return this.characters.filter(function (c) {      
          return c.collectionType === "cryptomaids" && !self.adminNames.includes(c.name)
      })
    }
  },
  methods: {
    fetchCreators: function(){
      var self = this;
      axios.get(`/api/users`)
      .then(function(res) {
        self.creators = res.data.users
      }).catch((err) => {
        console.log(err)
        // this.isLoading = false;                 
      })
    },
    fetchCharacters: function(){
      var self = this;
      self.characters = []
      axios.get(`/api/characters`)
      .then(function(res) {
        self.characters = res.data.characters

        console.log(self.characters)
        // yukiがデフォルト
        // const yuki = self.characters.find(c => c.name == "Yuki");
        // console.log(yuki)
        // self.selectCharacter(yuki);
      }).catch((err) => {
        console.log(err)
        // this.isLoading = false;                 
      })
    },
    fetchDerivatives: function(){
      var self = this;
      const queries = {
        character_id: this.selectedCharacter.id
      }
      axios.get(`/api/derivatives`, {params: queries})
      .then(function(res) {
        self.derivatives = res.data.derivatives
      }).catch((err) => {
        console.log(err)
        // this.isLoading = false;                 
      })
    },
    selectCharacter: function(character){
      this.dialog = true
      this.selectedCharacter = character
      this.fetchDerivatives()
    },
    toggleDerivativeDetail: function(derivative){
      this.selectedDerivative = derivative;
    },
    toggleDerivatives: function(){
      this.isDerivativesOpened = !this.isDerivativesOpened
    },
    // modal
    openModal: function(errorMessage) {
      this.modalMessage = errorMessage;
      this.modalStatus = true;
    },
    closeModal: function() {
      this.modalStatus = false;
    },
  },
}
</script>

<style scoped lang="scss"> 
  .no-result{
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    // background-color: #000;
    border-radius: 8px;
    p{
      font-weight: 600;
      font-size: 20px;
    }
  }
  .character-sections{
    // display: flex;
    // padding: 80px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .characters-section{
    max-width: 400px;
  }
  .character-section{
    // h2{
    //  font-size: 20px;
    //   font-weight: 600; 
    // }
    // h2 {
    //   width: 100%; 
    //   text-align: center; 
    //   border-bottom: 1px solid #000; 
    //   line-height: 0.1em;
    //   margin: 10px 0 20px; 
    // } 

    // h2 span { 
    //     background:#fff; 
    //     padding:0 10px; 
    // }
    h2 {
      overflow: hidden;
      text-align: center;
      font-size: 14px;
      font-weight: 600;
    }

    h2:before,
    h2:after {
      background-color: #fff;
      content: "";
      display: inline-block;
      height: 1px;
      position: relative;
      vertical-align: middle;
      width: 50%;
    }

    h2:before {
      right: 0.5em;
      margin-left: -50%;
    }

    h2:after {
      left: 0.5em;
      margin-right: -50%;
    }
  }
  .character-detail-section{
    flex: 1;
    h2{
     font-size: 20px;
      font-weight: 600; 
    }
    label{
      font-weight: 600;
    }
    // display: flex;./
  }
  .characters{
    // overflow-y: scroll;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    max-width: 920px;
  }
  .character{
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // padding: 8px;
    text-align: center;
    // background-color: #fff;
    border-radius: 4px;
    margin: 4px;
    cursor: pointer;
    @media(max-width: 567px){
      font-size: 11px;
      padding: 0;
      // padding-right: 10px;
    }
    &.active{
      background-color: lightseagreen;
    }
    &__image{
      height: 80px;
      // width: 60px;
      border-radius: 4px;
      object-fit: cover;
      margin-bottom: 4px;
      // background-color: #444;
      @media(max-width: 567px){
        height: 40px;
        width: 40px;
      }
    }
    &__name{
      font-size: 10px;
      font-weight: 600;
      // background-color: #eee;
      width: 100%;
      margin-top: 6px;
    }
  }
  .character-detail__label{
    font-weight: 600;
    margin-right: 8px;
  }
  .character-detail__item{
    font-size: 14px;
    margin-bottom: 4px;
    &__label{
      font-weight: 600;
      margin-right: 8px;
    }
    h4{
      font-weight: 600;
      font-size: 20px;
    }

    &--horizon{
      display: flex;
      align-items: center;
    }
  }
  .derivative-detail__item-label{
    font-size: 14px;
    font-weight: 600;
  }
  .creators{
    overflow-y: scroll;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    @media(max-width: 768px){
      max-height: 280px;
      overflow: scroll;
    }
  }
  .creator{
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    padding: 6px;
    text-align: center;
    // cursor: pointer;
    @media(max-width: 567px){
      padding: 2px;
    }
    &__image{
      // margin-bottom: 4px;
      height: 60px;
      width: 60px;
      border-radius: 50%;
      object-fit: cover;
      border: 4px solid transparent;
      &.active{
        border: 4px solid lightseagreen;
      }
      @media(max-width: 567px){
        margin-bottom: 4px;
        height: 60px;
        width: 60px;
      }
    }
    &__name{
      font-weight: 600;
      font-size: 10px;
      word-break: break-all;
      max-width: 62px;
      margin: 0 auto;
      transform: scale(0.8);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .derivative-section{
    margin-bottom: 8px;
    h2{
      font-weight: 600;
      font-size: 20px;
    }
  }
  .derivative-detail{
    // background: linear-gradient(rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.2) 30%, rgba(0, 0, 0, 0.2) 70%, rgba(0, 0, 0, 0.7) 100%);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,.8);
    z-index: 1000;
    overflow-y: scroll;
    color: #000;
    padding: 80px;
    @media(max-width: 567px){
      padding: 12px;
      padding-top: 100px;
    }
    &__content{
      max-width: 1200px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
    }
    &__image{
      width: 100%;
      // border-radius: 12px;
      background-color: #000;
      // margin-bottom: 20px;
      @media(max-width: 567px){
        margin-bottom: 12px;
      }
    }
    &__items{
      display: flex;
      flex-wrap: wrap;
      &:not(:last-child){
        margin-bottom: 20px;
      }
    }
    &__item{
      margin-top: 12px;
      margin-right: 20px;
    }
    &__item-label{
      font-size: 11px;
      display: block;
      line-height: 4px;
      color: #111;
    }
    &__item-value{
      font-size: 14px;
    }
    &__user{
      display: inline-flex;
      align-items: center;
      margin-bottom: 8px;
      margin-right: 12px;
      background-color: #eee;
      padding: 8px 20px 8px 8px;
      border-radius: 8px;
    }
    &__user-image{
      height: 24px;
      width: 24px;
      object-fit: cover;
      border-radius: 50%;
      margin-right: 8px;
    }
    &__user-name{
      font-weight: 600;
    }
    &__body-title{
      font-family: "Babas", cursive;
      color: lightseagreen;
      display: block;
    }
    &__body-wrapper{
      min-width: 260px;
    }
    &__body{
      background-color: rgba(255,255,255,1);
      // border: 7px double #999;
      padding: 20px;
      min-width: 260px;
      margin: 0 0 20px;
      // border-radius: 20px;
      @media(max-width: 567px){
        margin: 0 0 12px;
      }
    }
    &__item{

    }
    &__item-title{
      font-weight: 600;
      font-size: 11px;
    }
    &__item-value{
      border-bottom: 1px solid;
      word-break: break-all;
    }
  }
  .derivative{
    cursor: pointer;
    display: inline-block;
    width: 25%;
    margin-right: 8px;
    background-color: #eee;
    padding: 12px;
    background-size: cover;
    background-position: center;
    @media(max-width: 576px){
      width: 50%;
    }
    &:before{
        display: block;
        content: '';
        padding-top: 140%;
    }
    // @media(max-width: 920px){
    //   width: 33.3%;
    // }
    // @media(max-width: 567px){
    //   width: 50%;
    // }
    &__image{
      cursor: pointer;
      // padding: 8px;
      width: 100%;
      border-radius: 12px;
      // background-color: #111;
      // @media(max-width: 567px){
      //   padding: 4px;
      // }

      &:hover{
        opacity: .9;
      }
    }
    &__user-image{
      height: 28px;
      width: 28px;
      object-fit: cover;
      border-radius: 50%;
      position: absolute;
      bottom: 12px;
      left: 12px;
      @media(max-width: 567px){
        bottom: 8px;
        left: 8px;
      }
    }
    &__overlay{
      transition: .1s;
      opacity: 0;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.2) 30%, rgba(0, 0, 0, 0.2) 70%, rgba(0, 0, 0, 0.7) 100%);
      &:hover{
        opacity: 1;
      }
    }
  }
  .derivatives-close-button{
    // color: #060606;
    display: inline-block;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    position: fixed;
    right: 20px;
    top: 20px;
    background-color: rgba(255,255,255,.5);
  }
  .modal-desc{
    color: #000;
  }

  .doujin-art{
    display: inline-block;
    width: 25%;
    margin-right: 8px;
    background-color: #eee;
    padding: 12px;
    background-size: cover;
    background-position: center;
    @media(max-width: 576px){
      width: 50%;
    }
    &:before{
        display: block;
        content: '';
        padding-top: 140%;
    }
  }
  .derivatives{
    margin-top: 20px;
    overflow-x: scroll;
    white-space: nowrap;
  }
</style>
