<template>
  <div>
    <div class="text-center" v-if="times[3].time == 0 && times[2].time == 0 && times[1].time == 0 && times[0].time == 0">
      <a href="/" class="reload-link">Sale has started!! Please reload the page</a>
    </div>
    <div v-else>
      <slot v-bind:times="times"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    endTimeInUnixtime: { type: Number, require: true}
  },
  data() {
    return{
      times: [
        { id: 0, time: 1 },
        { id: 1, time: 1 },
        { id: 2, time: 1 },
        { id: 3, time: 1 }
      ],
      timeinterval: undefined
    }
  },
  computed: {
    endTimeInUnixtimeInMilliseconds() {
      return this.endTimeInUnixtime * 1000
    },
  },
  created: function() {
    this.updateTimer();
    this.timeinterval = setInterval(this.updateTimer, 1000);
  },
  methods: {
    updateTimer: function() {
      if(!this.endTimeInUnixtime) return

      if (
        this.times[3].time > 0 ||
        this.times[2].time > 0 ||
        this.times[1].time > 0 ||
        this.times[0].time > 0
      ) {
        this.getTimeRemaining();
      } else {
        clearTimeout(this.timeinterval);
      }
    },
    getTimeRemaining: function() {
      let t = this.endTimeInUnixtimeInMilliseconds - Date.parse(new Date());
      if(t >= 0){
        this.times[3].time = Math.floor(t / 1000 % 60); //seconds
        this.times[2].time = Math.floor(t / 1000 / 60 % 60); //minutes
        this.times[1].time = Math.floor(t / (1000 * 60 * 60) % 24); //hours
        this.times[0].time = Math.floor(t / (1000 * 60 * 60 * 24)); //days
       }else {
         this.times[3].time = this.times[2].time = this.times[1].time = this.times[0].time = 0;
         this.progress = 0;
       }
    },
  }
}
</script>

<style scoped>
  .reload-link{
    text-align: center;
    display: inline-block;
    margin: 20px auto;
    background-color: brown;
    padding: 20px;
  }
</style>