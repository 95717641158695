<template>
  <div class="shop-wrapper">
    <div class="container">
      <div class="shop">
        <div class="shop-image">
          <div class="shop-image-inner-bg">
            <div class="awaken-btns">
              <div v-show="!!showPixel" class="awaken-btn" @click="showPixel = false">TRY AWAKEN MODE / 覚醒モード</div>
              <div v-show="!showPixel" class="awaken-btn" @click="showPixel = true">BACK TO ORIGIN</div>
            </div>
            <!-- <div v-if="!!showDerivatives" class="awaken-btn" @click="showPixel = false">Super Maid Time</div> -->
            <img v-if="!!showPixel" :src="require('/app/assets/images/gif/maids/20.gif')" alt=""></img>
            <img v-else :src="require('/app/assets/images/gif/maids/kakusei.gif')" alt=""></img>
          </div>
        </div>

        <div class="shop-mint">
          <div v-show="isLoading" class="shop-loading">
            <div>
              <div class="shop-loading__content">
                <!-- <img :src="require('/app/assets/images/gif/maids/loading.png')" alt=""></img> -->
              </div>
              <div class="loader">Loading...</div>
              <!-- <div>...Loading</div> -->
            </div>
          </div>
          <div class="text-center" v-if="connectedWallet === null">
            <button @click="connect" class="wallet-connect-button--top">
              Connect Wallet
            </button>
          </div>
          <!-- プレセール中 && 対象の人 -->
          <div v-if="isPreSaleWhitelist && preSaleStatus">
            <div class="whitelist-desc">
              <h2 class="section__title section__title--lg text-center mb-0">Pre-Sale NOW!!</h2>
              People in whitelist can mint now.<br>
              Your max mint allocation: {{ preSaleAllocation }}
              <p class="mint-caution" v-if="isPartnerSaleWhitelist && partnerSaleAllocation > 0">Your max mint allocation at the partner sale: {{ partnerSaleAllocation }}</p>
            </div>

            <div class="mint-number-wrapper">
              <div @click="reduceNum('pre')" class="mint-number-btn">-</div>
              <div class="mint-number">{{ preSaleMintNum }}</div>
              <div @click="addNum('pre')" class="mint-number-btn">+</div>
            </div>

            <div class="mint-price">
              Total {{ calculateAmount(this.preSaleMintNum, this.preSalePrice) }} eth | {{ this.totalSupply || "?" }}/4000
            </div>

            <div class="mint-btn-wrapper">
              <span @click="preSaleMint" class="mint-btn">Mint CryptoMaids</span>
            </div>
          </div>


          <!-- プレセール中 && 対象でない人 -->
          <div v-else-if="preSaleStatus">
            <div class="whitelist-desc">
              <h2 class="section__title section__title--lg text-center mb-0">Pre-Sale NOW!!</h2>
              You are not in whitelist.<br>
              Your max mint allocation at the partner sale: {{ partnerSaleAllocation }}
            </div>
            <div class="sale-schedule">
              <div>{{ this.totalSupply || "?" }}/4000</div>
              <div>Partner Sale: 8/11 19:00 JST</div>
              <div>Public Sale: 8/11 21:00 JST</div>
            </div>
            <div class="twitter-timeline">
              <Timeline :id="userId" sourceType="profile" :options="{ tweetLimit: '3', theme: 'dark'}"/>
            </div>
          </div>

          <!-- パートナーセール中 && 対象の人 -->
          <!-- <div v-else-if="isPartnerSaleWhitelist && partnerSaleStatus"></div> -->
          <div v-else-if="isPartnerSaleWhitelist && partnerSaleStatus">
            <div class="whitelist-desc">
               <h2 class="section__title section__title--lg text-center mb-0">Partner Sale NOW!!</h2>
                People in the Partner Sale participant can mint now.<br>
                Your max mint allocation: {{ partnerSaleAllocation }}
            </div>
            <div class="mint-number-wrapper">
              <div @click="reduceNum('partner')" class="mint-number-btn">-</div>
              <div class="mint-number">{{ partnerSaleMintNum }}</div>
              <div @click="addNum('partner')" class="mint-number-btn">+</div>
            </div>

            <div class="mint-price">
              Total {{ calculateAmount(this.partnerSaleMintNum, this.partnerSalePrice) }} eth | {{ this.totalSupply || "?" }}/4000
            </div>

            <div class="mint-btn-wrapper">
              <span @click="partnerSaleMint" class="mint-btn">Mint CryptoMaids</span>
            </div>
          </div>

          <!-- パートナーセール中 && 対象でない人 -->
          <div v-else-if="partnerSaleStatus">
            <div class="whitelist-desc">
              <h2 class="section__title section__title--lg text-center mb-0">Partner Sale NOW!!</h2>
              You are not in the Partner Sale list. <br>
            </div>
            <div class="sale-schedule">
              <div>{{ this.totalSupply || "?" }}/4000</div>
              <div>Public Sale: 8/11 21:00 JST</div>
            </div>
            <div class="twitter-timeline">
              <Timeline :id="userId" sourceType="profile" :options="{ tweetLimit: '3', theme: 'dark'}"/>
            </div>
          </div>

          <!-- パブリックセール中 -->
          <!-- publicSaleStatus -->
          <div v-else-if="publicSaleStatus">
            <div class="whitelist-desc">
              <h2 class="section__title section__title--lg text-center mb-0">Public Sale NOW!!</h2>
              You can mint now.
            </div>

            <div class="mint-number-wrapper">
              <div @click="reduceNum('public')" class="mint-number-btn">-</div>
              <div class="mint-number">{{ mintNum }}</div>
              <div @click="addNum('public')" class="mint-number-btn">+</div>
            </div>

            <div class="mint-price">
              Total {{ calculateAmount(this.mintNum, this.publicSalePrice) }} eth | {{ this.totalSupply || "?"}}/4000
            </div>

            <div class="mint-btn-wrapper">
              <span @click="mint" class="mint-btn">Mint CryptoMaids</span>
            </div>
            <p class="mint-caution">
              Maximum 20 per transaction. <br>
              No limit on the number of transactions per wallet.
            </p>
          </div>

          <template v-else>
            <template v-if="preSaleStartTime !== null && preSaleStartTime > currentTime">
              <h2 class="section__title section__title--lg text-center mb-0">Pre-Sale<br>{{$t("mint.countdown")}}</h2>
              <countdown-timer :end-time-in-unixtime="preSaleStartTime">
                <template v-slot="slotProps">
                  <div class="maid-sale-countdown">
                    <div class="maid-sale-countdown__group">
                      <span class="maid-sale-countdown__num">{{ slotProps.times[0].time }}</span>
                      <span class="maid-sale-countdown__unit">{{$t("mint.unit.day")}}</span>
                    </div>
                    <div class="maid-sale-countdown__group">
                      <span class="maid-sale-countdown__num">{{ slotProps.times[1].time }}</span>
                      <span class="maid-sale-countdown__unit">{{$t("mint.unit.hour")}}</span>
                    </div>
                    <div class="maid-sale-countdown__group">
                      <span class="maid-sale-countdown__num">{{ slotProps.times[2].time }}</span>
                      <span class="maid-sale-countdown__unit">{{$t("mint.unit.minute")}}</span>
                    </div>
                    <div class="maid-sale-countdown__group">
                      <span class="maid-sale-countdown__num">{{ slotProps.times[3].time }}</span>
                      <span class="maid-sale-countdown__unit">{{$t("mint.unit.second")}}</span>
                    </div>
                  </div>
                </template>
              </countdown-timer>
            </template>

            <template v-else>
              <h2 class="section__title section__title--lg text-center mb-0">CRYPTOMAIDS MINT/SALE</h2>
            </template>

            <p class="mint-caution" v-if="isPreSaleWhitelist && preSaleAllocation > 0">Your max mint allocation at the pre sale: {{ preSaleAllocation }}</p>
            <p class="mint-caution" v-if="isPartnerSaleWhitelist && partnerSaleAllocation > 0">Your max mint allocation at the partner sale: {{ partnerSaleAllocation }}</p>
            <div class="mint-caution">You can't mint now.</div>
            <div class="sale-schedule">
              <div>{{ this.totalSupply }}/4000</div>
              <div>Pre Sale: 8/10 21:00 JST</div>
              <div>Partner Sale: 8/11 19:00 JST</div>
              <div>Public Sale: 8/11 21:00 JST</div>
            </div>
            <div class="twitter-timeline">
              <Timeline :id="userId" sourceType="profile" :options="{ tweetLimit: '3', theme: 'dark'}"/>
            </div>
          </template>

          <!-- ウォレット接続ボタン -->
          <div class="wallet-connect">
            <div v-if="connectedWallet !== null" class="wallet-description">
              Connected: ({{connectedWallet}})
            </div>
            <div v-else>
              <button @click="connect" class="wallet-connect-button">
                Connect Wallet
              </button>
            </div>
          </div>

          <!-- マークルルートのverify -->
          <div class="text-center mt-2">
            <div v-if="isPreSaleWhitelist">
              Pre Sale Verify: {{ preSaleMerkleTree.verify(preSaleProof, preSaleLeaf, preSaleRoot) }}
            </div>
            <div v-if="isPartnerSaleWhitelist">
              Partner Sale Verify: {{ partnerSaleMerkleTree.verify(partnerSaleProof, partnerSaleLeaf, partnerSaleRoot) }}
            </div>
            <div>
              <a href="/" style="border-bottom: 1px solid #fff">METAMASK BROWSER RELOAD/再読み込み</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- modal -->
    <div v-if="showModal" @click="closeModal" class="message-modal-wrapper d-flex">
      <v-icon class="message-modal-wrapper__close">fas fa-times</v-icon>
      <div class="message-modal">
        {{ modalMessage }}
      </div>
    </div>
  </div>
</template>
<script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
<script>
import { ethers } from "ethers";
import SaleAbi from 'abi/maids.json';
// ホワイトリストをAPI経由で取得したらこのファイルを書き換えてマークルルートを生成し、コントラクトに反映する
//　開発用
// import preSaleWhitelist from 'packs/assets/json/pre-sale-whitelists.json'
// import partnerSaleWhitelist from 'packs/assets/json/partner-sale-whitelists.json'
//　本番用
import preSaleWhitelist from 'packs/assets/json/pre-sale-whitelists-production.json'
import partnerSaleWhitelist from 'packs/assets/json/partner-sale-whitelists-production.json'
import keccak256 from "keccak256"
import preSaleMerkleTree from 'packs/pre_sale_merkle_tree'
import partnerSaleMerkleTree from 'packs/partner_sale_merkle_tree'
import CountdownTimer from "packs/components/utility/countdown-timer.vue";
import { Timeline } from "vue-tweet-embed";
export default {
  components: {
    CountdownTimer,
    Timeline
  },
  data: function() {
    return {
      isLoading: true,
      // contract
      contractAddressForDevelopment: "0x5069001C4a9bc384720E0075cfd832FfAF081665",
      // contractAddressForDevelopment: "0xfD802E4F53c0d05410d6f24566c07608a0485885",
      // production contract address
      contractAddress: "0xfD802E4F53c0d05410d6f24566c07608a0485885",
      contractAbi: SaleAbi,
      // data
      railsEnvironment: process.env.RAILS_ENV,
      walletAddress: "",
      isPreSaleWhitelist: false,
      isPartnerSaleWhitelist: false,
      preSaleAllocation: 5,
      partnerSaleAllocation: 0,
      preSaleRoot: '',
      preSaleLeaf: '',
      preSaleProof: '',
      partnerSaleRoot: '',
      partnerSaleLeaf: '',
      partnerSaleProof: '',
      mintNum: 1,
      preSaleMintNum: 1,
      partnerSaleMintNum: 1,
      totalSupply: 0,
      preSaleStatus: false,
      partnerSaleStatus: false,
      publicSaleStatus: false,
      connectedWallet: null,
      preSaleWhitelists: preSaleWhitelist,
      partnerSaleWhitelists: partnerSaleWhitelist,
      preSaleStartTime: null, //コントラクトと通信する前に表示したいので追加
      // modal
      modalMessage: "",
      showModal: false,
      showPixel: true,
      //  merkleTree
      preSaleMerkleTree: preSaleMerkleTree,
      partnerSaleMerkleTree: partnerSaleMerkleTree,

      // contract
      contract: null,
      preSalePrice: 0.05,
      partnerSalePrice: 0.055,
      publicSalePrice: 0.06,
      currentTime: Date.now() / 1000,

      userId: "CryptoMaids",
    }
  },
  created() {
    this.getSalesStatus();
    // 現在の環境によってアドレス切り替えを行う予定なのでしっかり本番で確認しておく
    // console.log(this.railsEnvironment)
  },
  mounted(){
    const self = this
    setTimeout(function(){self.isLoading = false},3000);
  },
  computed: {
    allocationString() {
      return `${this.allocation} ${this.allocation > 1 ? "NFTs" : "NFT"}`
    }
  },
  methods: {
    getSalesStatus: async function() {
      if(window.ethereum){
        const accounts = await window.ethereum.send('eth_requestAccounts');
        if (accounts.result.length > 0) {
          this.connectedWallet = accounts.result[0]
        }
      }
      let provider;
      if(typeof web3 != 'undefined') {
        provider = new ethers.providers.Web3Provider(web3.currentProvider);
      } else if(typeof window.ethereum != 'undefined'){
        provider = new ethers.providers.Web3Provider(window.ethereum);
      }
      // ウォレットログインしていなければ早期リターン
      if (provider === undefined) {
        this.isLoading = false
        alert("No wallet detected. Chrome browser with MetaMask is recommended. ウォレットが検知されませんでした。Google Chromeとメタマスクでの利用が推奨されています。")
        return
      }

      const { chainId } = await provider.getNetwork()
      if (chainId !== 1) {
        this.isLoading = false
        alert("Please switch your network to Ethereum Mainnet")
      }

      const signer = provider.getSigner();
      const address = await signer.getAddress();
      this.walletAddress = address

      // プレセール情報のセット
      const preSaleWhitelist = this.preSaleWhitelists.find((whitelist) => whitelist.address.toLowerCase() === address.toLowerCase())
      if (preSaleWhitelist !== undefined) {
        this.isPreSaleWhitelist = true
        this.preSaleAllocation = preSaleWhitelist.allocation
        this.preSaleLeaf = keccak256(ethers.utils.solidityPack(["address", "uint256"], [preSaleWhitelist.address, preSaleWhitelist.allocation]))
        this.preSaleProof = this.preSaleMerkleTree.getHexProof(this.preSaleLeaf)
        this.preSaleRoot =  this.preSaleMerkleTree.getRoot().toString('hex')
      }
      // パートナーセール情報のセット
      const partnerSaleWhitelist = this.partnerSaleWhitelists.find((whitelist) => whitelist.address.toLowerCase() === address.toLowerCase())
      if (partnerSaleWhitelist !== undefined) {
        this.isPartnerSaleWhitelist = true
        this.partnerSaleAllocation = partnerSaleWhitelist.allocation
        this.partnerSaleLeaf = keccak256(ethers.utils.solidityPack(["address", "uint256"], [partnerSaleWhitelist.address, partnerSaleWhitelist.allocation]))
        this.partnerSaleProof = this.partnerSaleMerkleTree.getHexProof(this.partnerSaleLeaf)
        this.partnerSaleRoot =  this.partnerSaleMerkleTree.getRoot().toString('hex')
      }

      // コントラクトの情報セット
      // TODO: 本番でセールを行うときには取得するコントラクトを変更する
      const contractAddress = this.railsEnvironment == 'development' ? this.contractAddressForDevelopment : this.contractAddress;
      this.contract = new ethers.Contract(contractAddress, this.contractAbi.abi, signer);

      // パブリックセールの開始時間取得
      const saleStartTime = await this.contract.saleStart();
      const saleEnd = await this.contract.saleEnd();
      const salePrice = await this.contract.salePrice()
      const preSalePrice = await this.contract.preSalePrice()
      const partnerSalePrice = await this.contract.partnerSalePrice()
      const maxSupply = await this.contract.maxSupply()

      // saleStartTimeが0の時はセール中ではないとみなす
      this.publicSaleStatus = saleStartTime != 0 && (Date.now() / 1000) > saleStartTime && (Date.now() / 1000) < saleEnd;

      const totalSupplyRes = await this.contract.totalSupply()
      this.totalSupply = totalSupplyRes.toNumber()

      // マークルルート
      this.preSaleStartTime = await this.contract.preSaleStart() * 1000 / 1000;
      const preSaleEnd = await this.contract.preSaleEnd();
      const preSaleMerkleRoot = await this.contract.preSaleMerkleRoot()

      // プレセールとパブリックセールが同時に行われることはない
      if(!this.publicSaleStatus) {
        // プレセールの開始時間取得
        // preSaleStartTimeが0の時はセール中ではないとみなす
        this.preSaleStatus = this.preSaleStartTime != 0 && (Date.now() / 1000) > this.preSaleStartTime && (Date.now() / 1000) < preSaleEnd
      }

      const partnerSaleStartTime = await this.contract.partnerSaleStart();
      const partnerSaleEnd = await this.contract.partnerSaleEnd();
      const partnerSaleMerkleRoot = await this.contract.partnerSaleMerkleRoot()

      // 他のセールが行われている時にパートナーセールは行われない
      if (!this.publicSaleStatus && !this.preSaleStatus) {
        // partnerSaleStartTimeが0の時はセール中ではないとみなす
        this.partnerSaleStatus = partnerSaleStartTime != 0 && (Date.now() / 1000) > partnerSaleStartTime && (Date.now() / 1000) < partnerSaleEnd
      }
      this.isLoading = false
    },
    preSaleMint: async function() {
      // ウォレットログインしていない場合return
      if (this.contract === null) {
        this.openModal("Please connect metamask before mint.");
        return
      }

      // ホワイトリストにアドレスがなかった場合はreturn
      if (!this.isPreSaleWhitelist) {
        this.openModal("Wallet address is incorrect.");
        return
      }

      // ミント数が購入可能数より大きければreturn
      if (this.preSaleMintNum > this.preSaleAllocation){
        this.openModal(`Your max allocation is ${this.preSaleAllocation}`);
        return;
      }

      try{
        const override = {
          value: ethers.utils.parseEther((this.calculateAmount(this.preSaleMintNum, this.preSalePrice)).toString()),
        }
        const tx = await this.contract.preSaleMint(
          this.preSaleMintNum,
          this.preSaleAllocation,
          this.preSaleLeaf,
          this.preSaleProof,
          override
        );
        this.openModal(`Tx has started! Transaction Hash is ${tx.hash}`);
      } catch(e) {
        console.log(e)
        if (e.reason !== undefined) {
          this.openModal(e.reason);
        } else {
          this.openModal("Error.");
        }
      }
    },
    partnerSaleMint: async function() {
      // ウォレットログインしていない場合return
      if (this.contract === null) {
        this.openModal("Please connect metamask before mint.");
        return
      }

      // ホワイトリストにアドレスがなかった場合はreturn
      if (!this.isPartnerSaleWhitelist) {
        this.openModal("Wallet address is incorrect.");
        return
      }

      // ミント数が購入可能数より大きければreturn
      if (this.partnerSaleMintNum > this.partnerSaleAllocation){
        this.openModal(`Your max allocation is ${this.partnerSaleAllocation}`);
        return;
      }

      try{
        const override = {
          value: ethers.utils.parseEther((this.calculateAmount(this.partnerSaleMintNum, this.partnerSalePrice)).toString()),
        }
        const tx = await this.contract.partnerSaleMint(
          this.partnerSaleMintNum,
          this.partnerSaleAllocation,
          this.partnerSaleLeaf,
          this.partnerSaleProof,
          override
        );
        this.openModal(`Mint succeeded! Transaction Hash is ${tx.hash}`);
      } catch(e) {
        console.log(e)
        if (e.reason !== undefined) {
          this.openModal(e.reason);
        } else {
          this.openModal("Error.");
        }
      }
    },
    mint: async function() {
      // ウォレットログインしていない場合return
      if (this.contract === null) {
        this.openModal("Please connect wallet before mint.");
        return
      }
      try{
        const override = {
          value: ethers.utils.parseEther(this.calculateAmount(this.mintNum, this.publicSalePrice).toString()),
        }
        const tx = await this.contract.mintNFT(this.mintNum, override);
        this.openModal(`Mint succeeded! Transaction Hash is ${tx.hash}`);
      } catch(e){
        console.log(e)
        if (e.reason !== undefined) {
          this.openModal(e.reason);
        } else {
          this.openModal("Error.");
        }
      }
    },
    reduceNum: function (kind) {
      if(kind == 'pre'){
        if(this.preSaleMintNum <= 1){
          return;
        }
        this.preSaleMintNum -= 1;
      } else if (kind === 'partner') {
        if(this.partnerSaleMintNum <= 1){
          return;
        }
        this.partnerSaleMintNum -= 1;
      } else{
        if(this.mintNum <= 1){
          return;
        }
        this.mintNum -= 1;
      }
    },
    addNum: function (kind) {
      if(kind == 'pre'){
        if(this.preSaleMintNum >= this.preSaleAllocation){
          this.openModal("You don't have enough allocations.")
          return;
        }
        this.preSaleMintNum += 1;
      } else if (kind === 'partner') {
        if(this.partnerSaleMintNum >= this.partnerSaleAllocation){
          this.openModal("You don't have enough allocations.")
          return;
        }
        this.partnerSaleMintNum += 1;
      } else {
        if(this.mintNum >= 20){
          this.openModal("You cannot mint more than 20 per transaction.")
          return;
        }
        this.mintNum += 1;
      }
    },
    // modal
    openModal: function(errorMessage) {
      this.modalMessage = errorMessage;
      this.showModal = true;
    },
    closeModal: function() {
      this.showModal = false;
    },
    // 小数点誤差の発生を防ぐ
    calculateAmount(num, price) {
      let amount = num * price
      amount *= 1000
      amount = Math.floor(amount)
      amount /= 1000
      return amount
    },
    connect() {
      this.getSalesStatus()
    }
  },
}
</script>

<style scoped lang="scss">
  .alert-bar{
    background-color: indianred;
    color: #fff;
    font-weight: 600;
  }
  .sale-schedule{
    text-align: center;
    margin: 20px;
  }
  .shop-wrapper{
    padding: 60px 0;
    background-color: rgba(0,0,0,.75);
    @media(max-width: 768px){
      padding: 0 0 40px;
    }
  }
  .shop{
    display: flex;
    justify-content: center;
    // align-items: center;
    @media(max-width: 768px){
      display: block;
    }
  }
  .shop-image{
    flex-basis: 50%;
    text-align: center;
    margin-right: 20px;
    @media(max-width: 768px){
      display: block;
      margin-right: 0;
    }
    &-inner-bg{
      margin: 0 auto;
      width: 100%;
      max-width: 500px;
    }
    img{
      width: 100%;
      // border-radius: 30px;
    }
  }
  .shop-mint{
    flex-basis: 50%;
    @media(max-width: 768px){
      margin-top: 40px;
    }
  }
  .whitelist-desc{
    font-size: 20px;
    text-align: center;
    color: white;
    margin-bottom: 20px;
    a{
      color: white;
      text-decoration: underline;
      &:hover{
        color: white;
        opacity: 0.8;
      }
    }
  }
  .mint-number-wrapper{
    margin: 0 auto;
    max-width: 350px;
    width: 100%;
    background-color: rgba(255,255,255,.9);
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px;
  }
  .mint-number-btn{
    padding: 10px 35px;
    background-color: black;
    color: white;
    font-size: 20px;
    text-align: center;
    border-radius: 10px;
    &:hover{
      cursor: pointer;
      opacity: 0.9;
    }
  }
  .mint-number{
    text-align: center;
    font-size: 35px;
    color: black;
  }
  .mint-price{
    font-size: 20px;
    color: white;
    text-align: center;
    margin: 20px 0;
  }
  .mint-btn-wrapper{
    text-align: center;
    margin: 0 0 40px 0;
    &:hover{
      cursor: pointer;
      opacity: 0.9;
    }
  }
  .mint-btn{
    width: 100%;
    max-width: 350px;
    display: inline-block;
    background-color: darkcyan;
    padding: 26px 40px;
    font-size: 22px;
    border-radius: 10px;
    color: #fff;
    font-weight: 600;
  }
  .mint-caution{
    text-align: center;
    font-size: 20px;
    color: white;
    @media(max-width: 768px){
      font-size: 15px;
    }
  }
  .message-modal-wrapper{
    word-break: break-all;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,.6);
    cursor: pointer;
    z-index: 1000;
    &__close.v-icon {
      color: #fff;
      position: absolute;
      right: 20px;
      top: 20px;
      cursor: pointer;
    }
  }
  .message-modal {
    pointer-events: none;
    min-width: 320px;
    max-width: 500px;
    min-height: 150px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    background-color: rgba(255,255,255,1);
    font-weight: 600;
    padding: 40px;
    border-radius: 12px;
    margin-top: 12px;
    color: #000;
    overflow-wrap: break-word;
    position: relative;
  }
  .wallet-connect {
    margin-top: 12px;
    display: flex;
    justify-content: center;
    word-break: break-all;
  }
  .wallet-connect-button {
    border-bottom: 1px solid #fff;
    outline: none;
    &--top{
      background-color: cornflowerblue;
      padding: 6px 18px;
      border-radius: 8px;
    }
  }
  .awaken-btns{
    margin-bottom: 20px;
    margin-top: 12px;
  }
  .awaken-btn{
    cursor: pointer;
    width: 100%;
    padding: 12px 20px;
    // margin-bottom: 16px;
    font-weight: 600;
    border: 2px solid #fff;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover{
      background-color: rgba(255,255,255,.2);
    }
  }
  .twitter-timeline{
    max-width: 460px;
    margin: 0 auto;
    max-height: 300px;
    overflow: scroll;
  }
  .shop-loading{
    position: fixed;
    top:0;
    right:0;
    left:0;
    bottom:0;
    background-color: rgba(0,0,0,.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    &__content{
      img{
        width: 100%;
        max-width: 320px;
      }
    }
  }
.loader {
  color: #ffffff;
  font-size: 90px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 72px auto;
  position: relative;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}
@-webkit-keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@-webkit-keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

</style>
