<template>
  <div class="shop-wrapper" :style="{ backgroundImage: 'url(' + require(`../../assets/images/butlers/background.png`) + ')' }">
    <div class="container">
      <div class="shop">
        <div class="shop-image">
          <img :src="require(`../../assets/images/butlers/butlers.png`)">
        </div>

        <div class="shop-mint">
          <template v-if="!isPublicSaleStarted" >
            <h2 class="section__title section__title--lg text-center">{{$t("mint.publicmint")}}<br>{{$t("mint.countdown")}}</h2>
            <countdown-timer :end-time-in-unixtime="publicSaleStartTime">
                <template v-slot="slotProps">
                    <div class="maid-sale-countdown"> 
                        <div class="maid-sale-countdown__group">
                            <span class="maid-sale-countdown__num">{{ slotProps.times[0].time }}</span>
                            <span class="maid-sale-countdown__unit">{{$t("mint.unit.day")}}</span>
                        </div>
                        <div class="maid-sale-countdown__group">
                            <span class="maid-sale-countdown__num">{{ slotProps.times[1].time }}</span>
                            <span class="maid-sale-countdown__unit">{{$t("mint.unit.hour")}}</span>
                        </div>
                        <div class="maid-sale-countdown__group">
                            <span class="maid-sale-countdown__num">{{ slotProps.times[2].time }}</span>
                            <span class="maid-sale-countdown__unit">{{$t("mint.unit.minute")}}</span>
                        </div>
                        <div class="maid-sale-countdown__group">
                            <span class="maid-sale-countdown__num">{{ slotProps.times[3].time }}</span>
                            <span class="maid-sale-countdown__unit">{{$t("mint.unit.second")}}</span>
                        </div>
                    </div>
                </template>
            </countdown-timer>
          </template>

          <template v-else >
            <h2 class="section__title section__title--lg text-center">{{$t("mint.publicLive")}}</h2>
          </template>

          <p v-if="!isWhitelisted && !isPublicSaleStarted" class="whitelist-desc">
            <template v-if="isWhitelistSaleStarted">{{$t("mint.whitelistmintOpened")}}<br></template> 
            <template v-else>{{$t("mint.whitelistmintDate")}}<br></template> 

            <template v-if="!linkAddress">{{$t("mint.connectDesc")}}<br></template> 
            <template v-else>{{$t("mint.notInWhitelist")}}<br></template> 
            {{$t("mint.whitelistGiveaway")}} <a target="_blank" href="https://discord.gg/KaEE4cVwDF">Discord</a>
          </p>

          <p v-else-if="isWhitelisted && !isPublicSaleStarted" class="whitelist-desc">
             {{$t("mint.yesInWhitelist")}} <br> 
            <!-- You are getting 0.01ETH discount🎉<br> -->
            <template v-if="isWhitelistSaleStarted">{{$t("mint.whitelistmintOpened")}}<br></template> 
            <template v-else>{{$t("mint.whitelistmintDate")}}<br></template> 
          </p>

          <div v-else class="whitelist-desc" style="max-width: 380px; margin: 0 auto">
            {{$t("mint.japanNft")}}🎉🎉🎉
          </div>

          <template v-if="isWhitelistSaleStarted">
            <template v-if="!isSoldOut">
              <div class="mint-number-wrapper">
                <div @click="reduceNum('public')" class="mint-number-btn">-</div>
                <div class="mint-number">{{ mintNum }}</div>
                <div @click="addNum('public')" class="mint-number-btn">+</div>
              </div>

              <div class="mint-price">
                Total {{ (mintNum * price).toFixed(2) }} eth  [ {{ totalSupply || 0 }} / {{ maxSupply }} ]
              </div>

              <div class="mint-btn-wrapper">
                <span v-if="!linkAddress" @click="linkSetup" class="mint-btn r-mint">{{$t("mint.connectImx")}}<br></span>
                <span v-else-if="(isPublicSaleStarted || (isWhitelisted && isWhitelistSaleStarted))" @click="mint" class="mint-btn r-mint">{{$t("mint.mintBtn")}}</span>
              </div>
              <p class="mint-caution">
                <span v-if="linkAddress">Immutable X: {{ linkAddress }}</span><br>
                {{$t("mint.maximum")}} <br>
                {{$t("mint.check")}} <br><a href="https://market.x.immutable.com/assets?collection=0x177c23722581262d2f76a419c4a4e9963dcf70ca" target="_blank" style="border-bottom: 1px solid #fff">the Immutable X marketplace</a>
              </p>
            </template>
            <template v-else>
              <div class="" style="text-align: center;font-weight: 600;font-size: 20px;color: lightgreen;">
                {{$t("mint.soldout")}}
              </div>
            </template>
          </template>

          <template v-else>
            <template v-if="!isSoldOut">
              <div class="mint-btn-wrapper">
                <span v-if="!linkAddress" @click="linkSetup" class="mint-btn r-mint">{{$t("mint.connectImx")}}</span>
              </div>
              <p class="mint-caution">
                <span v-if="linkAddress">Immutable X: {{ linkAddress }}</span><br>
                {{$t("mint.maximum")}} <br>
                {{$t("mint.check")}} <br><a href="https://market.x.immutable.com/assets?collection=0x177c23722581262d2f76a419c4a4e9963dcf70ca" target="_blank" style="border-bottom: 1px solid #fff">the Immutable X marketplace</a>
              </p>
            </template>
          </template>


          <!-- <div>
            railsEnvironment: {{ railsEnvironment }} <br>
            walletAddress: {{ walletAddress }} <br>
            whitelist: {{ whitelist }} <br>
            whitelist: {{ lcaseWhitelist }} <br>
            mintNum: {{ mintNum }} <br>
            whitelistMintNum: {{ whitelistMintNum }} <br>
            whitelistSaleStartTime: {{ whitelistSaleStartTime }} <br>
            isWhitelisted: {{ isWhitelisted }} <br>
            isWhitelistSaleStarted: {{ isWhitelistSaleStarted }} <br>
            isPublicSaleStarted: {{ isPublicSaleStarted }} <br>
            publicSaleStartTime: {{ publicSaleStartTime }} <br>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from 'packs/axios'
import isMobile from "ismobilejs";
import { ethers } from "ethers";
import ButlersAbi from 'abi/butlers.json';
import CountdownTimer from "packs/components/utility/countdown-timer.vue";
import { Link, ImmutableXClient, ImmutableMethodResults} from '@imtbl/imx-sdk';
export default {
  components: {
    CountdownTimer
  },
  props: ['totalSupply'],
  data: function() {
    return {
      isMobile,
      // contract
      contractAddressForDevelopment: "0x8f0c2E46B76a093fBD513823eA8ad3D67d79f6F9",
      contractAddress: "0x177C23722581262d2f76a419c4A4E9963DcF70cA",
      contractAbi: ButlersAbi,
  
      // data
      railsEnvironment: "production",
      walletAddress: "",
      whitelist: [
        "0xF2B097235feB9D5F4B897dA0c654c026524F2A81",
        "0xfF4563111C4Ae2e95Ffab1d64540e1c934e19474"
      ],
      proof: '',
      root: '',
      mintNum: 1,
      // totalSupply: 0,
      maxSupply: 555,
      mintGasLimit: 22464,
      whitelistMintNum: 1,
      linkAddress: "",
      // whitelistSaleStatus: false,
      // publicSaleStatus: false,
      currentTime: 0,
      publicSaleStartTime: 0,
      whitelistSaleStartTime: 0,
      // publicSaleStartTime: 1642251600,
      // whitelistSaleStartTime: 1642248000,

      // modal
      modalMessage: "",
      modalStatus: false,
    }
  },
  created() {
    // this.buildIMX();
    const locale = location.pathname.includes('/butler_test') ? 'ja' : 'en'
    this.$i18n.locale = locale
    this.currentTime = parseInt(Date.now() / 1000)
  },
  mounted () {
  },
  computed: {
    price: function(){
      return this.isWhitelisted ? 0.05 : 0.05;
    },
    leftSupply: function(){
      return this.maxSupply - this.totalSupply;
    },
    isSoldOut: function(){
      return this.leftSupply < 20;
    },
    isWhitelisted: function(){
      return this.lcaseWhitelist.includes(this.walletAddress.toLowerCase())
    },
    lcaseWhitelist: function(){
      return this.whitelist.map(x => x.toLowerCase())
    },
    isWhitelistSaleStarted: function(){
      return this.currentTime >= this.whitelistSaleStartTime;
    },
    isPublicSaleStarted: function(){
      return this.currentTime >= this.publicSaleStartTime;
    },
    linkUrl: function(){
      if(this.railsEnvironment != "production") {
        return 'https://link.ropsten.x.immutable.com'
      } else{
        return 'https://link.x.immutable.com'
      }
    }
  },
  methods: {
    // register and/or setup a user
    linkSetup: async function() {
      if(this.isMobile.phone){
        let result = window.confirm(this.$$.t("mint.usePc"));
        if(!result){
          return false;
        }
      }
      const link = new Link(this.linkUrl);
      // Register user, you can persist address to local storage etc.
      const { address } = await link.setup({});
      console.log("current wallet address", address)
      this.walletAddress = address
      this.linkAddress = address;
    },

    mint: async function() {
      let self = this;
      console.log("normal mint!!")
      try{
        if (window.ethereum) {
          try {
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });

            if(accounts[0] != this.linkAddress){
              alert(self.$i18n.t("mint.useSameAddress"))
              return 
            }
          } catch (error) {
            if (error.code === 4001) {
              // User rejected request
            }
          }
        }
        const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
        const signer = provider.getSigner();
        const address = await signer.getAddress();

        console.log("signer", signer)
        console.log("address", address)

        // 正しいネットワークかチェック
        const networkId = (await provider.getNetwork()).chainId;
        console.log("networkId", networkId)

        console.log("railsEnvironment", self.railsEnvironment)

        if((self.railsEnvironment == "production" && networkId != 1) || (self.railsEnvironment == "development" && networkId != 3)) {
          alert("Network is incorrect.");
          return;
        }

        const contractAddress = self.railsEnvironment == 'development' ? self.contractAddressForDevelopment : self.contractAddress;        
        console.log("contractAddress", contractAddress)

        const butlersContract = new ethers.Contract(contractAddress, self.contractAbi, signer);

        console.log("butlersContract", butlersContract)
        const override = {
          value: ethers.utils.parseEther((self.mintNum * self.price).toFixed(2).toString()),
          gasLimit: self.mintGasLimit
        }

        console.log("override", override)
        const tx = await butlersContract.mintButler(override);

        alert(self.$i18n.t("mint.success"))
      }catch(e){
        console.log(e)

        alert(self.$i18n.t("mint.fail"));
      }
    },
    reduceNum: function (kind) {
      let self = this;
      if(kind == 'whitelist'){
        if(self.whitelistMintNum <= 1){
          return;
        }
        self.whitelistMintNum -= 1;
      }else{
        if(self.mintNum <= 1){
          return;
        }
        self.mintNum -= 1;
      }
    },
    addNum: function (kind) {
      let self = this;
      if(kind == 'whitelist'){
        self.whitelistMintNum += 1;
      }else{
        if(self.mintNum >= 20 || self.mintNum >= self.leftSupply ){
          return;
        }
        self.mintNum += 1;
      }
    },

    // modal
    openModal: function(errorMessage) {
      this.modalMessage = errorMessage;
      this.modalStatus = true;
    },
    closeModal: function() {
      this.modalStatus = false;
    },
  },
}
</script>

<style scoped lang="scss">
  .shop-wrapper{
    background-size: cover;
    background-position: center;
    // padding: 60px 0;
    // font-family: 'Concert One', cursive;
  }
  .shop{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 80px 0;
    @media(max-width: 768px){
          display: flex;
          flex-direction: column-reverse;

      padding: 40px 0;
    }
  }
  .shop-image{
    flex-basis: 50%;
    text-align: center;
    img{
      width: 100%;
      // max-width: 450px;
      border-radius: 12px;
      @media(max-width: 768px){
        width: 100%;
      }
    }
  }
  .shop-mint{
    flex-basis: 50%;
    @media(max-width: 768px){
      margin-top: 40px;
    }
  }
  .whitelist-desc{
    font-size: 16px;
    text-align: center;
    color: white;
    // margin-bottom: 40px;
    padding-bottom: 20px;
    a{
      color: white;
      text-decoration: underline;
      &:hover{
        color: white;
        opacity: 0.8;
      }
    }
  }
  .mint-number-wrapper{
    margin: 0 auto;
    max-width: 350px;
    width: 100%;
    background-color: #060606;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px;
  }
  .mint-number-btn{
    padding: 10px 35px;
    background-color: black;
    color: white;
    font-size: 20px;
    text-align: center;
    border-radius: 10px;
    &:hover{
      cursor: pointer;
      opacity: 0.9;
    }
  }
  .mint-number{
    text-align: center;
    font-size: 35px;
    color: black;
  }
  .mint-price{
    font-size: 20px;
    color: white;
    text-align: center;
    margin: 20px 0;
  }
  .mint-btn-wrapper{
    text-align: center;
    margin: 0;
    &:hover{
      cursor: pointer;
      opacity: 0.9;
    }
  }
  .mint-btn{
    font-weight: 600;
    color: #000;
    width: 100%;
    max-width: 350px;
    display: inline-block;
    background-color: white;
    padding: 30px 40px;
    font-size: 22px;
    border-radius: 10px;
    // font-family: 'Concert One', cursive;
  }
  .mint-caution{
    text-align: center;
    font-size: 16px;
    color: white;
    @media(max-width: 768px){
      font-size: 15px;
    }
  }
  .modal-desc{
    color: #000;
  }
</style>
