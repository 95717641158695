const {MerkleTree} = require("merkletreejs")
const keccak256 = require("keccak256")
import { ethers } from "ethers";


const whitelists = require('./assets/json/pre-sale-whitelists-production.json')
// console.log(whitelists)


let leaves = whitelists.map(whitelist => keccak256(
  ethers.utils.solidityPack(["address", "uint256"], [whitelist.address, whitelist.allocation])
))

let merkleTree = new MerkleTree(leaves, keccak256, {sortPairs: true})

// Get root
let rootHash = merkleTree.getRoot().toString('hex')
// console.log(`pre sale merkle root: ${rootHash}`)

// ツリーの構造
// console.log(merkleTree.toString())

// 調べたいホワイトリスト
const whitelist = {address: '0x536198DFCe221F0e88b9E63CB57c4042cAFeCE34', allocation: 1}
let leaf = keccak256(
  ethers.utils.solidityPack(["address", "uint256"], [whitelist.address, whitelist.allocation])
)
// console.log(leaf.toString('hex'))
let proof = merkleTree.getHexProof(leaf)
// console.log(proof)

// Check proof
let v = merkleTree.verify(proof, leaf, rootHash)
// console.log(v) // returns true

export default merkleTree


