<template>
  <div class="menu-list-section">
    <div class="menu-list-inner-bg" style="margin-bottom: 20px;"> 
      <div class="text-center section-description menu-list-thumbnail">
        <img src="/images/links/shooting.png" alt="" class="menu-list-thumbnail-img">      
      </div>
      <div class="menu-list">
        <div class="menu-list-item" :class="!menu.locked ? 'active':''" @click="selectMenu(menu)" v-for="(menu, index) in menu" :key="`menu-list-${index}`">
          <!-- <img class="menu-list-image" v-if="menu.image" :src="menu.image" /> -->
          <span class="menu-list-item__name">{{ menu.title }}</span>
          <div v-if="menu.locked" class="locked-overlay">COMING SOON</div>
        </div>
      </div>
    </div>
    <v-dialog
      v-model="dialog"
      width="600px"
    >
      <v-card v-if="selectedMenu">
        <h4 class="home-modal__title">
          <span class="">{{selectedMenu.title}}</span>
        </h4>
        <v-card-text>
          <p class="home-modal__description">{{ selectedMenu.description }}</p>
        </v-card-text>
        <div style="margin: 16px;"> 
          <a :href="selectedMenu.link" target="_blank" class="home-modal__button">GO TO THE SITE</a>
        </div>
        <a :href="selectedMenu.link" target="_blank" class="">
          <img :src="selectedMenu.image" alt="" class="home-modal__image" style="margin-bottom: 20px">
        </a>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  components: {
    
  },
  data: function() {
    return {
      page: 1,
      totalCount: undefined,
      menu: [],
      selectedMenu: "",
      // modal
      modalMessage: "",
      modalStatus: false,
      locale: "en",
      dialog: false,
      menu:[
        {
          title: "CryptoMaids Shooting By POKENA",
          description: "Illustration - AKANE",
          titleJa: "CryptoMaids Shooting",
          descriptionJa: "Illustration - AKANE",
          image: "/images/links/shooting.png",
          locked: false,
          link: "https://cryptomaids-shooting.netlify.app/",
        },
        {
          title: "NFT Staking / $MAIDS",
          description: "CryptoMaids approved token, $MAIDS, has been released! It is an ERC20 token on Ethereum developed by the development club in the community. If you have CryptoMaids NFT, you can claim it Headers. Developped by POKENA, 千影, lideru",
          titleJa: "NFT Staking / $MAIDS",
          descriptionJa: "直接NFTを預け入れることなく、所有しているだけでステーキングを行なうことができる画期的なシステムです✨ ステーキングはガス代が不要ですが、$MAIDS獲得には必要なのでトークンを利用する直前まで引き出しを待つ事をお勧めします！ 開発者：POKENA, 千影, lideru",
          image: "/images/links/staking.png",
          locked: false,
          link: "https://made-in-maids.cryptomaids.tokyo/",
        },
        {
          title: ".",
          description: "The development of anonymous voting system for fanarts and onchain contest result. We will continue to host collab fan art contests with famous NFT projects to create many more amazing fan arts in our ecosystem.",
          titleJa: "Fan-art dApps",
          descriptionJa: "ファンアートへの匿名投票システムの開発とオンチェーンコンテストの結果。今後も、有名なNFTプロジェクトとのコラボファンアートコンテストを開催し、私たちのエコシステムでより多くの素晴らしいファンアートを創造していきます。",          
          image: "http://localhost:3000/assets/lp/ill-maid-up-4b8d2d7d9b6715ebde05f5e33b18990e7b732ab056132e303cbf9951d4fe2243.png",
          locked: true,
          link: "https://cryptomaids-shooting.netlify.app/",
        },
        {
          title: ".",
          description: "You can create your own anime or manga and even start selling merchandise based on your CryptoMaids NFT.",
          titleJa: "DoujinVerse",
          descriptionJa: "オリジナルのアニメやマンガを作り、NFTを題材にしたグッズの販売も始めることができます。",
          image: "http://localhost:3000/assets/lp/ill-maid-up-4b8d2d7d9b6715ebde05f5e33b18990e7b732ab056132e303cbf9951d4fe2243.png",
          locked: true,
          link: "https://cryptomaids-shooting.netlify.app/",
        },
      ]
    }
  },
  created() {
    this.locale = location.pathname.includes('/ja_home') ? 'ja' : 'en'
    // this.$i18n.locale = locale
    // this.currentTime = parseInt(Date.now() / 1000)

  },
  methods: {
    selectMenu: function(menu){
      if(!menu.locked){
        this.selectedMenu = menu
        this.dialog = true
      }
    },
    toggleDerivativeDetail: function(derivative){
      this.selectedDerivative = derivative;
    },
    toggleDerivatives: function(){
      this.isDerivativesOpened = !this.isDerivativesOpened
    },
    // modal
    openModal: function(errorMessage) {
      this.modalMessage = errorMessage;
      this.modalStatus = true;
    },
    closeModal: function() {
      this.modalStatus = false;
    },
  },
}
</script>

<style scoped lang="scss"> 
  .no-result{
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    // background-color: #000;
    border-radius: 8px;
    p{
      font-weight: 600;
      font-size: 20px;
    }
  }
  .menu-list-inner-bg{
    display: flex;
    @media(max-width: 756px){
      flex-direction: column;
    }
  }
  .menu-list-thumbnail{
    flex: 1;
    border-radius: 16px;
  }
  .menu-list-thumbnail-img{
    width: 100%;
    border-radius: 16px;
    margin-bottom: 20px;
  }
  .menu-list{
    flex: 1;
    max-width: 620px;
    width: 100%;
    margin: 0 auto;
    // display: flex;
    // flex-wrap: wrap;
    // margin: 0 -8px;
    // margin-top: 40px;
  }
  .menu-list-slide{
    position: relative;
    width: 100%;
    margin-bottom: 40px;
    @media(max-width: 756px){
      margin-bottom: 12px;
    }
    img{
      width: 100%;
      // border-radius: 20px;
      @media(max-width: 567px){
        border-radius: 0;
      }
    }
  }
  .menu-list-item{
    position: relative;
    // width: calc(50% - 24px);
    padding: 20px;
    margin: 8px;
    border-radius: 8px;
    // border: 1px solid;
    text-align: center;
    background-color: rgba(255,255,255,.1);
    @media(max-width: 567px){
      width: 100%;
    }
    &.active{
      cursor: pointer;
    }
  }
  .menu-list-section{
    // max-width: 1130px;
    margin: 0 auto;
    margin-top: 120px;
    @media(max-width: 780px){
    margin-top: 50px;
    }
  }
  .menu-list-item-image{
    width: 100%;
    // margin-bottom: 20px;
    padding: 20px;
  }
  .locked-overlay{
    position: absolute;
    top: 0;
    left:0;
    bottom: 0;
    right: 0;
    border-radius: 8px;
    background-color:rgba(0,0,0,.4);
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: italic;
    font-family: 'Babas';
    pointer-events: none;
  }
  .unlocked-overlay{
    position: absolute;
    top: 0;
    left:0;
    bottom: 0;
    right: 0;
    background-color:rgba(0,0,0,0);
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: italic;
    font-family: 'Babas';
    pointer-events: none;
  }
  .character-sections{
    display: flex;
  }
  .character-detail-section{
    flex: 1;
    h2{
     font-size: 20px;
      font-weight: 600; 
    }
    label{
      font-weight: 600;
    }
    // display: flex;./
  }
  .characters{
    // overflow-y: scroll;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    max-width: 520px;
  }
  .character{
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px;
    text-align: center;
    background-color: #eee;
    border-radius: 4px;
    margin: 4px;
    cursor: pointer;
    @media(max-width: 567px){
      font-size: 11px;
      padding: 0;
      padding-right: 10px;
    }
    &.active{
      background-color: lightseagreen;
    }
    &__image{
      height: 60px;
      width: 60px;
      border-radius: 4px;
      object-fit: cover;
      margin-bottom: 4px;
      // background-color: #444;
      @media(max-width: 567px){
        height: 40px;
        width: 40px;
      }
    }
    &__name{
      font-size: 10px;
      font-weight: 600;
    }
  }
  .character-detail__label{
    font-weight: 600;
    margin-right: 8px;
  }
  .character-detail__item{
    font-size: 14px;
    margin-bottom: 4px;
    &__label{
      font-weight: 600;
      margin-right: 8px;
    }
    h4{
      font-weight: 600;
      font-size: 20px;
    }

    &--horizon{
      display: flex;
      align-items: center;
    }
  }
  .derivative-detail__item-label{
    font-size: 14px;
    font-weight: 600;
  }
  .creators{
    overflow-y: scroll;
    display: flex;
    align-items: flex-start;
  }
  .creator{
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    padding: 6px;
    text-align: center;
    cursor: pointer;
    @media(max-width: 567px){
      padding: 2px;
    }
    &__image{
      margin-bottom: 4px;
      height: 80px;
      width: 80px;
      border-radius: 50%;
      object-fit: cover;
      border: 4px solid transparent;
      &.active{
        border: 4px solid lightseagreen;
      }
      @media(max-width: 567px){
        margin-bottom: 4px;
        height: 60px;
        width: 60px;
      }
    }
    &__name{
      font-weight: 600;
      font-size: 11px;
      word-break: break-all;
      max-width: 62px;
      margin: 0 auto;
    }
  }
  .derivative-section{
    margin-bottom: 8px;
    h2{
      font-weight: 600;
      font-size: 20px;
    }
  }
  .derivative-detail{
    // background: linear-gradient(rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.2) 30%, rgba(0, 0, 0, 0.2) 70%, rgba(0, 0, 0, 0.7) 100%);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,.8);
    z-index: 1000;
    overflow-y: scroll;
    color: #000;
    padding: 80px;
    @media(max-width: 567px){
      padding: 12px;
      padding-top: 100px;
    }
    &__content{
      max-width: 1200px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
    }
    &__image{
      width: 100%;
      // border-radius: 12px;
      background-color: #000;
      // margin-bottom: 20px;
      @media(max-width: 567px){
        margin-bottom: 12px;
      }
    }
    &__items{
      display: flex;
      flex-wrap: wrap;
      &:not(:last-child){
        margin-bottom: 20px;
      }
    }
    &__item{
      margin-top: 12px;
      margin-right: 20px;
    }
    &__item-label{
      font-size: 11px;
      display: block;
      line-height: 4px;
      color: #111;
    }
    &__item-value{
      font-size: 14px;
    }
    &__user{
      display: inline-flex;
      align-items: center;
      margin-bottom: 8px;
      margin-right: 12px;
      background-color: #eee;
      padding: 8px 20px 8px 8px;
      border-radius: 8px;
    }
    &__user-image{
      height: 24px;
      width: 24px;
      object-fit: cover;
      border-radius: 50%;
      margin-right: 8px;
    }
    &__user-name{
      font-weight: 600;
    }
    &__body-title{
      font-family: "Babas", cursive;
      color: lightseagreen;
      display: block;
    }
    &__body-wrapper{
      min-width: 260px;
    }
    &__body{
      background-color: rgba(255,255,255,1);
      // border: 7px double #999;
      padding: 20px;
      min-width: 260px;
      margin: 0 0 20px;
      // border-radius: 20px;
      @media(max-width: 567px){
        margin: 0 0 12px;
      }
    }
    &__item{

    }
    &__item-title{
      font-weight: 600;
      font-size: 11px;
    }
    &__item-value{
      border-bottom: 1px solid;
      word-break: break-all;
    }
  }
  .derivative{
    cursor: pointer;
    display: inline-block;
    width: 25%;
    margin-right: 8px;
    background-color: #eee;
    padding: 12px;
    background-size: cover;
    background-position: center;
    @media(max-width: 576px){
      width: 50%;
    }
    &:before{
        display: block;
        content: '';
        padding-top: 140%;
    }
    // @media(max-width: 920px){
    //   width: 33.3%;
    // }
    // @media(max-width: 567px){
    //   width: 50%;
    // }
    &__image{
      cursor: pointer;
      // padding: 8px;
      width: 100%;
      border-radius: 12px;
      // background-color: #111;
      // @media(max-width: 567px){
      //   padding: 4px;
      // }

      &:hover{
        opacity: .9;
      }
    }
    &__user-image{
      height: 28px;
      width: 28px;
      object-fit: cover;
      border-radius: 50%;
      position: absolute;
      bottom: 12px;
      left: 12px;
      @media(max-width: 567px){
        bottom: 8px;
        left: 8px;
      }
    }
    &__overlay{
      transition: .1s;
      opacity: 0;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.2) 30%, rgba(0, 0, 0, 0.2) 70%, rgba(0, 0, 0, 0.7) 100%);
      &:hover{
        opacity: 1;
      }
    }
  }
  .derivatives-close-button{
    // color: #060606;
    display: inline-block;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    position: fixed;
    right: 20px;
    top: 20px;
    background-color: rgba(255,255,255,.5);
  }
  .modal-desc{
    color: #000;
  }

  .doujin-art{
    display: inline-block;
    width: 25%;
    margin-right: 8px;
    background-color: #eee;
    padding: 12px;
    background-size: cover;
    background-position: center;
    @media(max-width: 576px){
      width: 50%;
    }
    &:before{
        display: block;
        content: '';
        padding-top: 140%;
    }
  }
  .derivatives{
    margin-top: 20px;
    overflow-x: scroll;
    white-space: nowrap;
  }
  .home-modal{

  }
</style>
