import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import colors from 'vuetify/lib/util/colors'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
        dark: {
            primary: "#ff2d55",
            secondary: "rgb(166, 87, 237)",
            accent: "#cddc39",
            error: "#ffa500",
            warning: "#ffc107",
            info: "#ff5722",
            success: "#795548",
            background: "#111b27",
        },
    },
  },
})