<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper" @click.self="$emit('close-modal')">
        <div class="modal-container">
          <i class="fa fa-times modal-close-btn" @click="$emit('close-modal')"></i>
          <slot name="body">
          </slot>
          <div class="modal-footer" v-if='!this.$slots.footer'>
            <slot name="footer">
              <button class="modal-btn modal-btn--sucess modal-btn--single" @click="$emit('close-modal')" v-if="modalSuccess">
                OK
              </button>
              <button class="modal-btn modal-btn--alert modal-btn--single" @click="$emit('close-modal')" v-else>
                Close
              </button>
            </slot>
          </div>
          <div class="modal-footer" v-else>
            <slot name="footer">
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>


<script>
export default {
  props: ['modalSuccess'],
  data: function () {
    return {
    }
  },
}
</script>

<style scoped>
 .modal-footer > div{
   width: 100%;
 }
</style>
